import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, Typography, TextField } from '@material-ui/core';
import ScrollableTable from '../common/ScrollableTable';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { AccountContext } from '../../Context';
import { withRouter } from 'react-router-dom';
import constants from '../../utils/constants';
import withWidth from '@material-ui/core/withWidth';

const styles = theme => ({
  dialogContainer: {
    display: 'flex',
    flexGrow: '1',
    minHeight: 280,
    marginLeft: 12,
    marginRight: 12,
  }, 
  fab: {
    margin: theme.spacing.unit,
    float: 'right',
  }, control: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit/2,
  },
})

class ProjectSelectDialog extends React.Component {
  state = {
    currentProject: null,
    createMode: false,
  };

  setProject = (e) => {
    let { projects } = this.props;
    this.setState({currentProject: projects[e.index]})
  };

  saveCurrentProject = () => {
    // pass it up to the handler
    let { saveProject, history } = this.props;
    const {projectName, projectDesc} = this.state;
    if (saveProject) {
      let p = {name: projectName, description: projectDesc};
      history.push(constants.RTE_LOCATIONS);
      saveProject(p);
    }
  };

  addProjectClick = () => {
    this.setState({createMode: true})
  }

  onCancel = (e) => {
   const { onCloseHandler } = this.props;
   const { createMode, editProject }  = this.state;
   if (createMode || editProject) {
     this.setState({createMode: false, editProject: undefined})
   } else {
     onCloseHandler(e)
   }
  };

  onEdit = (e) => {

  };

  onOpen = (e) => {
    // const { setProject } = this.context;
    const { currentProject } = this.state;
    const { openProject } = this.props;
  
    if (currentProject) {
      openProject(currentProject);
      // window.location.reload()
    }
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };
  
  render() {
    const { classes, open, onCloseHandler} = this.props;
    const { createMode, projectName, currentProject  } = this.state;
    const title = "Select a Project";
    const cancel = "Cancel";
    const openText = "Open";
    const save = "Save";
    const disableBtns = !!(createMode ? projectName === "" : currentProject === null);
    return (
      <div>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={open}
          onClose={onCloseHandler}
          maxWidth={'sm'}
          fullWidth={true}
        >
          <DialogTitle style={{verticalAlign: 'middle'}}>
              {title}
              <Fab onClick={this.addProjectClick} style={{marginTop: 0}} size="small" color="primary" aria-label="Add" className={classes.fab}>
                <AddIcon />
              </Fab>
          </DialogTitle>
          <div className={classes.dialogContainer}>
          <DialogContent> 
            {this.getDialogContent()}
          </DialogContent>
          </div>
          <DialogActions>
            <Button onClick={this.onCancel} color="primary">{cancel}</Button>
            {/* <Button disabled={disableBtns} onClick={this.onEdit} color="primary">Edit</Button> */}
            <Button disabled={disableBtns} onClick={createMode ? this.saveCurrentProject : this.onOpen}>{createMode ? save: openText}</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }

  getDialogContent() {
    const { classes, projects } = this.props;
    const { createMode, editProject } = this.state;
    const namePlaceholder = 'Name';
    const descPlaceholder = 'Description';
    const noProjectsTexts = "Please create your first project";
    const columns = this.getColumns()
    let dialogContent = (
      <div style={{width: '100%', textAlign:'center', verticalAlign:'middle'}}>
        <Typography variant='h5'>{noProjectsTexts}</Typography>
      </div>
    );
    if (createMode) {
      dialogContent = (
        <div>
          <TextField className={classes.control} autoFocus fullWidth id="projectName" name="projectName" placeholder={namePlaceholder} onChange={this.handleChange('projectName')} value={this.state.projectName}required/>
          <TextField className={classes.control} fullWidth id="projectDesc" multiline name="projectDesc" placeholder={descPlaceholder} onChange={this.handleChange('projectDesc')} value={this.state.projectDesc} rows="4" rowsMax="4"/>
        </div>
      );
    } else if (editProject) {

    } else if (projects && projects.length) {
      dialogContent = (
        <ScrollableTable
          minHeight={280}
          rowCount={projects.length}
          rowGetter={({ index }) => projects[index]}
          onRowClick={this.setProject}
          columns={columns}
        />
      );
    }
    return dialogContent;
  }

  getColumns() {
    const { width }= this.props;
    const columns = [
      {
        width: 1000,
        flowGrow: 1.0,
        label: 'Name',
        dataKey: 'name',
      }
    ];
    if (width !== 'sm' && width !== 'xs') {
      columns.push({
        width: 1500,
        flexGrow: 1.0,
        label: 'Description',
        dataKey: 'description',
      });
    }
    return columns;
  }
}

const Wrapper = withRouter(ProjectSelectDialog);
Wrapper.WrappedComponent.contextType = AccountContext;

export default withWidth()(withStyles(styles, { withTheme: true })(Wrapper));