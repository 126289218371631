import React from 'react';
import PageBase from '../common/PageBase';
import { Grid, Paper, withStyles, Typography } from '@material-ui/core';
import { AutoSizer } from 'react-virtualized';

const MIN_HEIGHT_MEDIA = 450;
const MEDIA_MARGIN = 40;
const MAX_MEDIA_WIDTH = 940;

const tutorialSections = [
  {title: "1. Create a Project:", link: "https://www.youtube.com/embed/6tsCkjg1gJ4"},
  {title: "2. Adding Criteria:", link: "https://www.youtube.com/embed/nEydOamD7PQ"},
  {title: "3. Adding Locations:", link: "https://www.youtube.com/embed/B8ahKsLxQ2I"},
  {title: "4. Starting a Session:", link: "https://www.youtube.com/embed/32Z-gEtoCVM"},
  {title: "5. Edit/Delete and Export Data:", link: "https://www.youtube.com/embed/Q0RHhvV-yrE"},
  {title: "6. View Metrics:", link: "https://www.youtube.com/embed/Q-8rSkNkxcw"},
]

const styles = theme => ({
  paperBase: {
    width: '100%',
    height: MIN_HEIGHT_MEDIA + 80,
    marginBottom: theme.spacing.unit,
  },
  img: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    marginLeft: 10,
  },
  subtitle: {
    marginLeft: 12,
  }
});

class TutorialPage extends React.Component {
  generateTutorialSection = (title, link, key) => {
    const { classes } = this.props;
    return (
      <Grid key={key} item xs={12}>
        <Paper className={classes.paperBase}>
          <Typography className={classes.title} variant="h6" gutterBottom>{title}</Typography>
          <AutoSizer>
            {({width}) => {
              let finalWidth = MAX_MEDIA_WIDTH < width ? MAX_MEDIA_WIDTH : width,
                marginLeft = Math.max((width - finalWidth)/2, MEDIA_MARGIN/2);
                return (<iframe style={{marginLeft: marginLeft}} width={finalWidth - 40} height={MIN_HEIGHT_MEDIA} src={link} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>)
            }}
          </AutoSizer>
        </Paper>
      </Grid>
    );
  };

  render() {
    return (
      <PageBase header="Tutorials">
        <Typography variant="subtitle1" gutterBottom>
          Below are all the tutorials required for successful behavior mapping. If 
          you're new to BMAPIT™ we suggest watching the turorials in order. However, it's not a requirment 
          and the videos can be viewed in any order.
        </Typography>
        <Grid container direction="row" justify="center" alignItems="center">
          {tutorialSections.map(s => {
            return this.generateTutorialSection(s.title, s.link, s.title)
          })}
        </Grid>
      </PageBase>
    )
  };
}
export default withStyles(styles)(TutorialPage);