import Axios from "axios";
import constants from '../utils/constants';

const saveSession = (projectId, session) => {
  let method = constants.POST_METHOD,
    url = getUrl(projectId);
  
  if (session.sessionId) {
    method = constants.PUT_METHOD;
    url = getUrl(projectId, session.sessionId);
  }
  return Axios({
    method: method,
    url: url,
    config: {
      headers: constants.CONTENT_TYPE.JSON,
    },
    data: session
  });
};

const deleteSession = (projectId, sessionId) => {
  return Axios({
    method: constants.DELETE_METHOD,
    url: getUrl(projectId, sessionId),
  });
};

const deleteSessions = (projectId, sessionIds) => {
  return Axios({
    method: constants.PUT_METHOD,
    url: constants.API_SESSIONS_DELETE.replace(':projectId', projectId),
    config: {
      headers: constants.CONTENT_TYPE.JSON
    },
    data: sessionIds
  });
};

const getSessions = (projectId, locationId, withCount) => {
  let params = undefined;
  if (locationId) {
    params = {
      locationId: locationId,
    }
  }
  if (withCount) {
    params.withCount = withCount;
  }
  return Axios({
    method: constants.GET_METHOD,
    url: getUrl(projectId),
    params: params ? params : undefined,
  })
};

function getUrl(projectId, sessionId) {
  if (sessionId) {
    return constants.API_SESSION.replace(':projectId', projectId).replace(':sessionId', sessionId);
  }
  return constants.API_SESSIONS.replace(':projectId', projectId);
}

export default {
  saveSession,
  deleteSession,
  getSessions,
  deleteSessions
};