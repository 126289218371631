const base = window.location.protocol+'//'+window.location.hostname+(window.location.port ? ':'+window.location.port: '');
const papisV1 = `${base}/bm/papis/v1`;
const papisV1Project = `${papisV1}/projects/:projectId`;
export default {
  // routes
  RTE_ERROR_NOT_FOUND: '/not-found',
  RTE_INDEX: '/',
  RTE_LOGIN: '/login',
  RTE_PROJECTS: '/projects',
  RTE_SESSION: '/locations/:id/newSession',
  RTE_LOCATION_DATA: '/locations/:id/data',
  RTE_CRITERIA: '/criteria',
  RTE_LOCATIONS: '/locations',
  RTE_METRICS: '/metrics',
  RTE_TUTORIALS: '/tutorials',
  
  API_LOGIN: `${base}/bm/signin`,
  API_LOGOUT: `${base}/bm/signout`,
  API_SESSION_CONTEXT: `${base}/bm/context`,
  API_CRITERIAS: `${papisV1Project}/criterias`,
  API_CRITERIAS_NODE_COUNTS: `${papisV1Project}/criterias/nodeCounts`,
  API_CRITERIA: `${papisV1Project}/criteria/:criteriaId`,
  API_CRITERIA_ADD_VALUE: `${papisV1Project}/criteria/:criteriaId/addCriteriaValue`,
  API_CRITERIA_REMOVE_VALUE: `${papisV1Project}/criteria/:criteriaId/removeCriteriaValues`,
  API_PROJECTS: `${papisV1}/projects`,
  API_PROJECT: `${papisV1Project}`,
  API_PROJECT_SUMMARY: `${papisV1Project}/dashboard/summary`,
  API_SET_LAST_PROJECT: `${papisV1Project}/_lastProject`,
  API_ACCOUNT: `${papisV1}/accounts/:accountId`,
  API_LOCATIONS: `${papisV1Project}/locations`,
  API_LOCATION: `${papisV1Project}/locations/:locationId`,
  API_LOCATION_FLOORPLAN_UPLOAD: `${papisV1Project}/locations/:locationId/uploadFloorPlan`,
  API_SESSION: `${papisV1Project}/session/:sessionId`,
  API_SESSIONS: `${papisV1Project}/sessions`,
  API_SESSIONS_DELETE: `${papisV1Project}/sessions/_delete`,
  API_NODES: `${papisV1Project}/nodes`,
  API_NODE: `${papisV1Project}/nodes/:nodeId`,
  API_NODE_UPLOAD_IMAGE: `${papisV1Project}/nodes/:nodeId/_uploadImage`,
  API_NODES_BY_LOCATION: `${papisV1Project}/locations/:locationId/nodes`,
  API_NODES_REPORT_BY_LOCATION: `${papisV1Project}/locations/:locationId/nodes/report`,

  AUTH_COOKIE: 'x-bmapit-auth',
  // HTTP
  GET_METHOD: 'GET',
  POST_METHOD: 'POST',
  PUT_METHOD: 'PUT',
  DELETE_METHOD: 'DELETE',
  CONTENT_TYPE_JSON: 'application/json',

  MODES: {
    EDIT: 'EDIT',
    CREATE: 'CREATE',
  },

  CONTENT_TYPE: {
    JSON: {'Content-Type': 'application/json'}
  },

  BASE_ERROR_HANDLER: (response, always) => {
    always && always(response)
    if (response.status !== 401) {
      alert(response)
    }
  }
};