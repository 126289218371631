import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#546e7a',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: will be calculated from palette.secondary.main,
      main: '#e53935',
      // dark: will be calculated from palette.secondary.main,
      //contrastText: '#ffcc00',  -- OR -- contrastText: will be calculated to contrast with palette.primary.main
    },
    // error: will use the default color
  },
  typography: {
    useNextVariants: true,
  },
});