import React from "react";

class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.autocompleteInput = React.createRef();
    this.autocomplete = null;
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
  }

  componentDidMount() {
    this.autocomplete = new window.google.maps.places.Autocomplete(this.autocompleteInput.current,
        {"types": ["geocode"]});

    this.autocomplete.addListener('place_changed', this.handlePlaceChanged);
  }

  handlePlaceChanged(){
    const place = this.autocomplete.getPlace();
    this.props.onPlaceLoaded(place);
  }



  render() {
    return (
      <div>
        {this.props.component ? 
          this.props.component
        :
          <input style={{width: '238px', height: '36px', position: 'absolute', marginTop: '-478px', marginLeft: '25%'}} ref={this.autocompleteInput} id="autocomplete" placeholder="Enter your address"
          type="text"></input>
        }
      </div>
    );
  }
}

export default SearchBar;