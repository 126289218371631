import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import ProjectSelectDialog from './ProjectSelectDialog';
import { AccountContext } from '../../Context';
import constants from '../../utils/constants';
import { withRouter } from 'react-router-dom';
import projectService from '../../services/projectService';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
  },
});

class ProjectSelect extends React.Component {
  // TODO: This component should open a modal window that allows the user
  // to select the current project. The selected project should be saved in 
  // some global context (** look into React.Context https://reactjs.org/docs/context.html)
  // This component should also support a seperate view, within the modal
  // that allows the user to create a new project
  state = {
    open: false,
  };


  componentDidMount= () => {
    let { account } = this.context;
    if (!account || !account.accountId) {
      return;
    }

    projectService.getProjects().then(response => {
      if (response.status === 200) {
        this.setState({projects: response.data});
      }
    }).catch(constants.BASE_ERROR_HANDLER);
  };

  saveProject = (project) => {
    const { setProject } = this.context;
    const { history, closeSidebar } = this.props;

    if (!project) {
      return;
    }
    // close dialog and attempt to close topbar
    this.setState({open: false});
    if (closeSidebar) {
      closeSidebar(false);
    }

    projectService.save(project).then(response => {
      if (response.status === 200) {
        setProject(response.data);
        history.push(constants.RTE_CRITERIA);
      }
    }).catch(constants.BASE_ERROR_HANDLER)

  };

  setOpen = (open) => () => {
    this.setState({open: open});
  };

  openProject = (project) => {
    const { setProject } = this.context;
    const { history } = this.props;
    if (project) {
      setProject(project);
      this.setState({open: false});
      history.push(constants.RTE_LOCATIONS);
    }
  };

  render() {
    const { classes, currentProject, baseComponent} = this.props;
    const { projects, open } = this.state;
    const selectProject = "Select a Project";
    return (
      <div>
        {baseComponent ? 
          React.cloneElement(baseComponent, {onClick: this.setOpen(true)})
          : 
          <Button variant="text" onClick={this.setOpen(true)}>{currentProject ? currentProject : selectProject}</Button>
        }
        <ProjectSelectDialog saveProject={this.saveProject} openProject={this.openProject} className={classes.formControl} onCloseHandler={this.setOpen(false)} projects={projects} open={open}></ProjectSelectDialog>
      </div>
    );
  }

};

ProjectSelect.propTypes = {
  classes: PropTypes.object.isRequired,
};

const Wrapper = withRouter(ProjectSelect);
Wrapper.WrappedComponent.contextType = AccountContext;

export default withStyles(styles)(Wrapper);