import React from 'react';
import { withStyles, Grid, Paper, TextField, Button } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Map from '../common/Map';
import FileUploader from '../common/FileUpload';

const styles = (theme) => ({
  root: {
    flexGrow: 1
  }, 
  paper: {
    marginTop: theme.spacing.unit,
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  control: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit/2,
  },
})

class LocationCreateEdit extends React.Component {
  state = {
    fileUrl: undefined,
    file: undefined,
    location: {
      bearing: 0,
      opacity: 0.8,
    },
  };

  // TODO refactor this
  static getDerivedStateFromProps(props) {
    const location = props.location
    if (location && location.locationId) {
      return {location: location, fileUrl: location.overlayUrl}
    }
    return null;
  }

  isEditing = () => {
    const { location } = this.state;
    return !!(location && location.locationId);
  };

  save = (e) => {
    e.preventDefault();
    let { location, file } = this.state,
      { saveAndUpload } = this.props;
    
    if (location && location.name) {
      if (saveAndUpload) {
        saveAndUpload(location, file);
        return;
      }
    }
    alert('You must provide a name for the location');
  } 

  fileUploadHandler = (e) => {
    if (this.isEditing()) {
      return;
    }
    let file = (e && e.currentTarget && e.currentTarget.files && e.currentTarget.files[0]) || undefined;
    if (file) {
      let url = URL.createObjectURL(file);
      this.setState({fileUrl: url, file: file});
    }
  };

  handleChange = (item) => (e) => {
    let {location} = this.state,
      newLocation = Object.assign(location, {[item]: e.target.value})
    this.setState({location: newLocation});
  };

  render() {
    const { classes, onCancel } = this.props;

    return (
      <Grid className={classes.root} container direction="row" justify="flex-start" alignItems="flex-start">
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <form>
              <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                <Grid item xs={4}>
                  <Grid container direction="column" justify="center" alignItems="center">
                    <TextField className={classes.control} autoFocus fullWidth id="locationName" name="locationName" placeholder={"Location Name"} required value={this.state.location.name} onChange={this.handleChange('name')}/>
                    <TextField className={classes.control} fullWidth id="locationDesc" name="locationDesc" placeholder={"Description"} multiline rows="4" rowsMax="4" value={this.state.location.description} onChange={this.handleChange('description')}/>
                    <div style={{width: '100%', textAlign: "left", marginTop: '12px', marginBottom: '12px'}}>
                      <FileUploader accept="image/*" onChange={this.fileUploadHandler} disabled={this.isEditing()}
                        component={
                          <Button variant="contained" color="default" component="span" className={classes.button} disabled={this.isEditing()}>
                            Upload Floorplan
                            <CloudUploadIcon style={{marginLeft: '8px'}}/>
                          </Button>
                        }></FileUploader>
                    </div>
                    <Button className={classes.control} onClick={onCancel}
                      type="cancel" variant="contained" fullWidth>
                        {"Cancel"}
                    </Button>
                    <Button className={classes.control} onClick={this.save}
                      type="submit" variant="contained" color="primary" fullWidth>
                        {"Save"}
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={8} style={{paddingLeft: '18px'}}>
                  <Map floorplanUrl={this.state.fileUrl} showSearchBar={false} onPlaceLoaded={this.onPlaceLoaded}></Map>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(LocationCreateEdit);