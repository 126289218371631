import React from 'react';
import { CircularProgress, withStyles } from '@material-ui/core';

const styles = () => ({
  dimScreen: {
    position: 'fixed',
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(232, 236, 241, 0.8)',
    textAlign: 'center',
    zIndex: 2000,
  },
  spinner: {
    marginTop: '30%',
  }
})

class UIBlocker extends React.Component {
  render() {
    const { block, classes } = this.props;
    if (!block) {
      return null;
    }
    return (
      <div className={classes.dimScreen}>
        <CircularProgress className={classes.spinner} />
      </div>
    );
  }
}

export default withStyles(styles)(UIBlocker);