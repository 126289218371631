import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LandingTopBar from './LandingTopBar';
import LandingContent from './LandingContent';

const styles = theme => {
  // add syles
}

class LandingPage extends React.Component {
  state={
    login: false,
  };

  loginHandler = (e) => {
    let { login } = this.state;
    this.setState({
      login: !login,
    });
  }

  render() {
    let { login } = this.state;
    return (
      <React.Fragment>
        <LandingTopBar loginHandler={this.loginHandler}/>
        <LandingContent login={login} />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(LandingPage);