import React, { Component } from 'react';
import MainTopBar from './MainTopBar';
import { AccountContext } from '../../Context';
import { Typography, Grid } from '@material-ui/core';

class MainLayout extends Component {
  render() {
    const { project } = this.context;
    const projectSelected = project && project.projectId;
    return (
      <div className="app-root-container">
        <MainTopBar project={project}/>
        {projectSelected ?
          <React.Fragment>
            {this.props.children}  
          </React.Fragment>
        :
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid style={{textAlign: "center", marginTop: '12px'}} item xs ={10}>
              <Typography variant="h4">Please select a project to get started</Typography>
            </Grid>
          </Grid>}
      </div>
    )
  }
}
MainLayout.contextType = AccountContext;

export default MainLayout;