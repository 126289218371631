import React from 'react';
import { Grid, AppBar, Toolbar, IconButton, List, InputLabel, Paper, Dialog, ListItem, ListItemText, FormControl, Select, MenuItem, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Map from '../common/Map';
import { AccountContext } from '../../Context';
import { withRouter } from 'react-router-dom';
import constants from '../../utils/constants';
import NodeCreationViewDialog from './NodeCreationViewDialog';
import UIBlocker from '../common/UIBlocker';
import sessionService from '../../services/sessionService';
import locationService from '../../services/locationService';
import criteriaService from '../../services/criteriaService';
import windowUtils from '../../utils/windowUtils';
import nodeService from '../../services/nodeService';
import PageBase from '../common/PageBase';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
  },
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  rootItem: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit*2,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  fullWidth: {
    width: '100%',
  }
});

class SessionPage extends React.Component {
  state = {
    nodes: [],
    location: undefined,
    criterias: undefined,
    open: false,
    // current node could be named currentMarkerPos
    currentNode: undefined, 
    // is a marker that was selected after being created
    viewNode: undefined,
    block: false,
    session: undefined,
  };

  componentDidMount() {
    let { project } = this.context;
    let { match } = this.props;
    // parse the url to get the location
    if (project && match && match.params && match.params.id) {
      const locationId = parseInt(match.params.id);
      // create the new session
      this.setState({block: true}, () => {
        // fetch locations
        locationService.getLocation(project.projectId, locationId)
        .then(({status, data }) =>{
          if (status === 200) {
            this.setState({location: data})
          }
        }).catch(constants.BASE_ERROR_HANDLER);

        // fetch criteria
        criteriaService.getCriterias(project.projectId)
        .then(({status, data}) => {
          if (status === 200) {
            this.setState({criterias: data})
          }
        }).catch(constants.BASE_ERROR_HANDLER)
        .then(() => this.setState({block: false}));
      });
    }
  };

  componentWillUnmount() {
    windowUtils.warnOnReload(false);
  }

  render() {
    const { classes } = this.props;
    const { location, open, criterias, nodes, viewNode, block, session } = this.state;
    
    // TODO refactor this - this changes the submit button based on whether a session has been created
    let submitText = "Start",
      submitHandler = this.startSession,
      deleteButton = null;
    if (session) {
      // then change start button to finish and create the delete button
      submitText = "Finish"
      submitHandler = this.finishSession;
      deleteButton = (<Button style={{float: 'right', marginTop: '10px', backgroundColor: 'red', color: 'white'}} variant="contained" onClick={this.deleteSession}>Delete</Button>)
    }
    const title = `Session ${(location && location.name) || ''}`
    return (
      <React.Fragment>
        <UIBlocker block={block}></UIBlocker>
        <PageBase header={title}>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start">
            <Grid xs={12}>
              <Grid container className={classes.root} direction="row" justify="center" alignItems="stretch"></Grid>
                <Grid xs={12}>
                  <Paper style={{padding: 12}}>
                    <Map markers={nodes} 
                      onClick={this.onMapClick} 
                      mapElement={<div style={{height: '80%', minHeight: '540px'}} />} 
                      floorplanUrl={location && location.overlayUrl}
                      zoomLimit={15}
                      onMarkerClick={this.onMarkerClick}
                    >
                    </Map>
                  </Paper>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button style={{float: 'right', marginTop: '10px', marginLeft: 10, marginBottom: '10px'}} variant="contained" onClick={submitHandler}>{submitText}</Button>
              {deleteButton}
            </Grid>
          </Grid>
        </PageBase>
        <NodeCreationViewDialog 
          open={open} 
          handleClose={this.criteriaDialogClose} 
          handleSave={this.criteriaDialogSave} 
          criterias={criterias} 
          handleDelete={this.deleteMarker}
          viewNode={viewNode}
        ></NodeCreationViewDialog>
      </React.Fragment>
    )
  };

  // this should create a menu where criteria are set
  onMapClick = (e) => {
    // means we are creating a new node
    const { session } = this.state;
    const { openAlert } = this.props;

    if (!session) {
      openAlert({
        contentText: "Please start a session before you start collecting data."
      });
      return;
    }

    const node = {
      latitude: e.lat,
      longitude: e.lng,
    }
    // popup to select characteristics
    this.setState({
      open: true,
      currentNode: node,
    });
  };

  onMarkerClick = (e, marker) => {
    // determine which marker was clicked based off the lat long
    const position = e.latLng;
    const { nodes } = this.state;
    if (nodes && position) {
      for (let node of nodes) {
        if (node.nodeId === marker.nodeId) {
          this.setState({viewNode: node, open: true});
          break;
        }
      }
    }
  };

  deleteMarker = () => {
    const { nodes, viewNode } = this.state;
    const { project } = this.context;
    var newNodes = nodes.filter(node => node.nodeId !== viewNode.nodeId);

    if (project) {
      nodeService.deleteNode(project.projectId, viewNode.nodeId)
      .then(({status}) => {
        if (status === 204) {
          this.setState({
            nodes: newNodes, 
            open: false, 
            currentNode: undefined,
            viewNode: undefined,
          });
        }
      }).catch(constants.BASE_ERROR_HANDLER);
    }
  };

  criteriaDialogSave = (criteriaValues, nodeDesc, imageBase64) => {
    // take the criteria values
    const { project } = this.context;
    const { currentNode, nodes, session, location } = this.state;
    const cleanup = (data) => {
      this.setState((state) => ({
        open: false,
        nodes: data ? state.nodes.concat(data) : state.nodes,
        currentNode: undefined,
        viewNode: undefined,
      }), () => {this.setState({block: false})})
    };

    if (currentNode && session && nodes && location) {
      let updatedNode = Object.assign(currentNode, {criteriaValues: criteriaValues, description: nodeDesc, sessionId: session.sessionId, locationId: location.locationId})
      this.setState({block: true}, () => {
        nodeService.saveNodes(project.projectId, [updatedNode])
        .then(({status, data}) => {
          if (status === 200) {
            if (imageBase64) {
              nodeService.uploadImage(project.projectId, data[0].nodeId, {image: imageBase64})
              .then (({status, data}) => {
                if (status === 200) {
                  cleanup(data)
                }
              });
            } else {
              cleanup(data)
            }
          }
        })
        .catch(constants.BASE_ERROR_HANDLER)
        // .then(() => this.setState({block: false}))
      });
    }
  };

  criteriaDialogClose = () => {
    this.setState({
      open: false,
      currentNode: undefined,
      viewNode: undefined,
    });
  };

  startSession = () => {
    const { project } = this.context;
    const { location } = this.state;

    if (project && location) {
      windowUtils.warnOnReload(true);
      sessionService.saveSession(project.projectId, {locationId: location.locationId})
      .then(({status, data}) => {
        if (status === 200) {
          this.setState({session: data});
        }
      }).catch(constants.BASE_ERROR_HANDLER);
    }
  };

  deleteSession = () => {
    const { history, openAlert } = this.props;
    const { session } = this.state;
    const { project } = this.context;

    if (session && project) {
      const delSess = () => {
        sessionService.deleteSession(project.projectId, session.sessionId)
          .then(({status}) => {
            if (status === 204) {
              history.push(constants.RTE_LOCATIONS);
            }
          }).catch(constants.BASE_ERROR_HANDLER);
      };
      
      openAlert({
        contentText: 'Are you sure you want to delete this session? This will delete all associated nodes. This cannot be undone.',
        okText: 'Confirm',
        onOk: delSess
      });
    }
  };

  finishSession = () => {
    windowUtils.warnOnReload(false);
    alert('Saved Successfully');
    this.props.history.push(constants.RTE_LOCATIONS)
  }

  renderCriteriaSelection = () => {
    let { criterias, open } = this.state;
    let { classes } = this.props;
    return (
      <div>
        <Dialog fullScreen open={open} onClose={this.criteriaDialogClose}>
          <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton color="inherit" onClick={this.criteriaDialogClose} aria-label="Close">
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <form className={classes.rootItem} autoComplete="off">
              <List className={classes.fullWidth}>
                {criterias.map((criteria) => (
                  <Grid className={classes.fullWidth} container direction="row" justify="center" alignItems="center">
                    <Grid item xs={7}>
                      <ListItem className={classes.fullWidth} key={criteria.criteriaId}>
                      <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item xs={6}>
                          <ListItemText primary={criteria.name}></ListItemText>
                          <FormControl style={{display: 'inline'}} className={classes.formControl}>
                            <InputLabel htmlFor="age-simple">{criteria}</InputLabel>
                          </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                          <Select
                            className={classes.fullWidth}
                            value={10}
                            inputProps={{
                              name: criteria.name,
                              id: criteria.criteriaId,
                            }}
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value={10}>Ten</MenuItem>
                            <MenuItem value={20}>Twenty</MenuItem>
                            <MenuItem value={30}>Thirty</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                    </ListItem>
                  </Grid>
                </Grid>
                ))}
            </List>
          </form>
        </Dialog>
      </div>
    )
  };
}

SessionPage.contextType = AccountContext;

export default withStyles(styles)(withRouter(SessionPage));