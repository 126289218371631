import Axios from "axios";
import constants from '../utils/constants';


const getLocationNodes = (projectId, locationId, sessionIds) => {
  let params = undefined;
  if (sessionIds && sessionIds.length > 0) {
    params = {
      sessionIds: sessionIds.reduce((prev, current) => prev + `,${current}`, '').substring(1),
    }
  }
  return Axios({
    method: constants.GET_METHOD,
    url: constants.API_NODES_BY_LOCATION.replace(':projectId', projectId).replace(':locationId', locationId),
    params: params,
  })
};

const saveNodes = (projectId, nodes) => {
  return Axios({
    method: constants.POST_METHOD,
    url: constants.API_NODES.replace(':projectId', projectId),
    config: {
      headers: constants.CONTENT_TYPE.JSON,
    },
    data: nodes
  });
};

const uploadImage = (projectId, nodeId, imageBase64) => {
  return Axios({
    method: constants.PUT_METHOD,
    url: constants.API_NODE_UPLOAD_IMAGE.replace(':projectId', projectId).replace(':nodeId', nodeId),
    data: imageBase64,
    config: {
      headers: constants.CONTENT_TYPE.JSON,
    }
  });
};

const deleteNode = (projectId, nodeId) => {
  return Axios({
    method: constants.DELETE_METHOD,
    url: constants.API_NODE.replace(':projectId', projectId).replace(':nodeId', nodeId),
  });
};

export default {
  getLocationNodes,
  saveNodes,
  deleteNode,
  uploadImage,
};