import React from 'react';
import { Button } from '@material-ui/core'

class FileUploader extends React.Component {
  render() {
    let { accept, multiple, component, onChange, ...props } = this.props;
    return (
      <div>
        <input
          accept={accept}
          style={{ display: 'none' }}
          id="raised-button-file"
          multiple={multiple}
          type="file"
          onChange={onChange}
          {...props}
        />
        <label htmlFor="raised-button-file">
          {component ?
            component
          : 
            <Button variant="contained" component="span" {...props}>
              Upload
            </Button>
          }
        </label> 
      </div>
    )
  }
}

export default FileUploader;