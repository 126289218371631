import Axios from "axios";
import constants from '../utils/constants';


const getLocations = (projectId) => {
  if (!projectId) {
    return Promise.reject(new Error('invalid projectId'));
  }
  return Axios({
    method: constants.GET_METHOD,
    url: constants.API_LOCATIONS.replace(':projectId', projectId),
  })
};

const getLocation = (projectId, locationId) => {
  return Axios({
    method: constants.GET_METHOD,
    url: getUrl(projectId, locationId)
  });
};

const save = (projectId, location) => {
  if (!projectId) {
    return Promise.reject(new Error('invalid projectId'));
  }
  if (!location) {
    return Promise.reject(new Error('invalid location'));
  }
  
  let method = constants.POST_METHOD,
    url = constants.API_LOCATIONS.replace(':projectId', projectId);
  if (location.locationId) {
    method = constants.PUT_METHOD;
    url = constants.API_LOCATION.replace(':projectId', projectId).replace(':locationId', location.locationId);
  }
  return Axios({
    method: method,
    url: url,
    config: {
      headers: constants.CONTENT_TYPE.JSON
    },
    data: location,
  });
};

const uploadFloorplan = (projectId, locationId, file) => {
  if (!projectId) {
    return Promise.reject(new Error('invalid projectId'));
  }
  if (!locationId) {
    return Promise.reject(new Error('invalid location'));
  }
  if (!file) {
    return Promise.reject(new Error('invalid file'));
  }

  let formData = new FormData();
  formData.append('file', file);

  return Axios({
    method: constants.POST_METHOD,
    url: constants.API_LOCATION_FLOORPLAN_UPLOAD.replace(':projectId', projectId).replace(':locationId', locationId),
    data: formData,
    config: { 
      headers: {
        'Content-Type': 'multipart/form-data' 
      }
    }
  });
};

function getUrl(projectId, locationId) {
  if (locationId) {
    return constants.API_LOCATION.replace(':projectId', projectId).replace(':locationId', locationId);
  }
  return constants.API_LOCATIONS.replace(':projectId', projectId);
}

export default {
  getLocations,
  getLocation,
  save,
  uploadFloorplan,
};