import React from 'react'
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, FormControl, Typography, Select, List, ListItem, MenuItem, TextField, Chip, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import arrayUtils from '../../utils/arrayUtils';
import Camera , { FACING_MODES } from 'react-html5-camera-photo';
import AddAPhotoRounded from '@material-ui/icons/AddAPhotoRounded';
import '../../../css/camera.css';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 80,
    width: '100%',
    maxWidth: 220,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  photoIcon: {
    marginLeft: 'auto',
    padding: 0,
  }
});

// NodeCreationViewDialog - component that allows user select a set of criteria values for a new node
// The nodeCriteriaValue should be a map where criteriaId -> criteriaValues
class NodeCreationViewDialog extends React.Component {
  state ={
    nodeCriteriaValue: undefined,
    nodeDesc: undefined,
    cameraMode: false,
    imageBase64: undefined,
  };

  // TODO refactor this
  static getDerivedStateFromProps(props) {
    const viewNode = props.viewNode
    if (viewNode && viewNode.criteriaValues && viewNode.criteriaValues.length > 0) {
      let nodeCriteriaValue = {},
        nodeDesc = viewNode.description;

      for (let cv of viewNode.criteriaValues) {
        if (!nodeCriteriaValue[cv.criteriaId]) {
          nodeCriteriaValue[cv.criteriaId] = [cv.criteriaValueId];
        } else {
          nodeCriteriaValue[cv.criteriaId].push(cv.criteriaValueId);
        }
      }
      return {nodeCriteriaValue, nodeDesc};
    }
    return null;
  }

  criteriaValueSelectHandler = (criteria) => (e) => {
    let { nodeCriteriaValue } = this.state;
    // merge the new value 
    let newValues = Object.assign(nodeCriteriaValue || {}, {[criteria.criteriaId]: e.target.value})
    this.setState({
      nodeCriteriaValue: newValues,
    });
  }

  nodeDesriptionHandler = (e) => {
    if (e && e.target) {
      this.setState({nodeDesc: e.target.value})
    }
  };

  onSave = () => {
    // get the criteria values selected and return them
    const { handleSave, criterias } = this.props;
    const { nodeCriteriaValue, nodeDesc, imageBase64 } = this.state;

    if (handleSave) {
      // TODO - SR: this is gross, need to refactor
      var selectedCriteriaValues = [];
      if (nodeCriteriaValue && criterias) {
        criterias.forEach(criteria => {
          let cvIds = nodeCriteriaValue[criteria.criteriaId];
          if (cvIds && criteria.criteriaValues) {
            criteria.criteriaValues.forEach(cv => {
              if (cvIds.length && cvIds.length > 0 && cvIds.find(id => id === cv.criteriaValueId)) {
                selectedCriteriaValues.push(cv);
              } else {
                if (cvIds === cv.criteriaValueId) {
                  selectedCriteriaValues.push(cv);
                }
              }
            })
          }
        })
      }
      handleSave(selectedCriteriaValues, nodeDesc, imageBase64);
      this._resetState();
    }
  };

  onDelete = () => {
    this.props.handleDelete && this.props.handleDelete();
    this._resetState();
  };

  onClose = () => {
    this.props.handleClose && this.props.handleClose();
    this._resetState();
  };

  addPhoto = () => {
    this.setState({cameraMode: true});
  };

  onTakePhoto = (dataUri) => {
    this.setState({cameraMode: false, imageBase64: dataUri})
  };

  deselectCriteriaValue = (cv) => (e) => {
    let { nodeCriteriaValue } = this.state;
    if (nodeCriteriaValue) {
      if (nodeCriteriaValue[cv.criteriaId] && nodeCriteriaValue[cv.criteriaId].length > 0) {
        let newCVs = nodeCriteriaValue[cv.criteriaId].filter(newCv => newCv.criteriaValueId !== cv.criteriaValueId),
          newValues = Object.assign(nodeCriteriaValue || {}, {[cv.criteriaId]: newCVs});

        this.setState({
          nodeCriteriaValue: newValues,
        });
      }
    }
  };

  render() {
    const { open, classes, criterias, viewNode } = this.props;
    const { cameraMode } = this.state;
    if (!criterias && !viewNode) {
      return (<div></div>)
    }
    if (cameraMode) {
      return this.renderCamera();
    }

    return (
        <Dialog 
          disableBackdropClick
          disableEscapeKeyDown
          open={open || false}
          onClose={this.onClose}
        >
          <DialogTitle>Please select the criteria values for this node</DialogTitle>
          <DialogContent>
            <form className={classes.container}>
              
              <List style={{width: '100%'}}>
                <ListItem>
                  <IconButton className={classes.photoIcon} onClick={this.addPhoto}>
                    <AddAPhotoRounded></AddAPhotoRounded>
                  </IconButton>
                </ListItem>
                {criterias.filter(criteria => criteria.criteriaValues && criteria.criteriaValues.length > 0).map(criteria => (
                  <ListItem key={criteria.criteriaId}>
                    <FormControl className={classes.formControl}>
                      <Typography variant="h6">{criteria.name}</Typography>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                      {this.renderSelectFromCriteria(criteria)}
                    </FormControl>
                  </ListItem>
                ))}
                <ListItem>
                  <FormControl className={classes.formControl}>
                    <TextField 
                      disabled={!!viewNode}
                      className={classes.control} 
                      fullWidth 
                      id="nodeDesc" 
                      multiline 
                      name="nodeDesc" 
                      placeholder={"Description"} 
                      onChange={this.nodeDesriptionHandler} 
                      value={this.state.nodeDesc} 
                      rows="4" 
                      rowsMax="4"/>
                  </FormControl>
                </ListItem>
              </List>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onClose} >Cancel</Button>
            {!!viewNode ?
              <Button onClick={this.onDelete}>Delete</Button>
            :  
              <Button onClick={this.onSave}>Save</Button>
            }
          </DialogActions>
        </Dialog>
    )
  };

  renderSelectFromCriteria = (criteria) => {
    const { classes, viewNode } = this.props;
    let { nodeCriteriaValue } = this.state,
      disabled = !!viewNode;
    if (!criteria) {
      return null;
    }
    var criteraValueMap = arrayUtils.arrToMap('criteriaValueId', criteria.criteriaValues);
    if (criteria.multivalued) {
      return (
        <Select
          multiple
          disabled={disabled}
          value={(nodeCriteriaValue && nodeCriteriaValue[criteria.criteriaId]) || []}
          onChange={this.criteriaValueSelectHandler(criteria)}
          renderValue={selectedValueIds => {
            return selectedValueIds.map(id => {
              var cv = criteraValueMap[id];
              let label = cv.value;
              return (
                <div className={classes.chips}>
                  <Chip key={id} label={label} onDelete={disabled ? undefined : this.deselectCriteriaValue(cv)}/>
                </div>
              );
            });
          }}
        >
          {criteria.criteriaValues.map(value => (
            <MenuItem key={value.criteriaValueId} value={value.criteriaValueId}>{value.value}</MenuItem>
          ))}
        </Select>
      );
    } else {
      return (
        <Select
          disabled={disabled}
          value={nodeCriteriaValue && nodeCriteriaValue[criteria.criteriaId]}
          onChange={this.criteriaValueSelectHandler(criteria)}
        >
          {criteria.criteriaValues.map(value => (
            <MenuItem key={value.criteriaValueId} value={value.criteriaValueId}>{value.value}</MenuItem>
          ))}
        </Select>
      );
    }
  }

  renderCamera = () => {
    return (
      <div style={{position: 'absolute', top: 0, left: 0, height: '100vh', width: '100vw', textAlign:'center', paddingTop: 120, backgroundColor: 'black'} }>
        <Camera onTakePhoto={this.onTakePhoto} idealFacingMode={FACING_MODES.ENVIRONMENT} isImageMirror={false}></Camera>
      </div>
    )
  };

  _resetState = () => {
    this.setState({
      nodeCriteriaValue: undefined,
      nodeDesc: undefined,
      imageBase64: undefined,
    });
  };
}

export default withStyles(styles)(NodeCreationViewDialog);