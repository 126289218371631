const warnOnReload = (enable) => {
  // this is the old way, need to find a better way
  if (enable) {
    window.onbeforeunload = windowReloaderBlocker();
    return;
  }
  window.onbeforeunload = null;
}

function windowReloaderBlocker() {
  return () => 'Are you sure';
}

export default {
  warnOnReload
}