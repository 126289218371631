import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListIconRounded from '@material-ui/icons/ListRounded';
import RoomIconRounded from '@material-ui/icons/RoomRounded';
import AssignmentRounded from '@material-ui/icons/AssignmentRounded';
import ProjectSelect from '../project-select/ProjectSelect';
import InsertChartRounded from '@material-ui/icons/InsertChartRounded';
import Movie from '@material-ui/icons/Movie';
import { Divider } from '@material-ui/core';
import Link from 'react-router-dom/Link';
import constants from '../../utils/constants';

const styles = {
  list: {
    width: 250,
  }
};

const SideBarItems = [
  {
    label: 'Location',
    icon: <RoomIconRounded/>,
    link: constants.RTE_LOCATIONS,
  }, {
    label: 'Criteria',
    icon: <AssignmentRounded/>,
    link: constants.RTE_CRITERIA,
  }, {
    label: 'Metrics (BETA)',
    icon: <InsertChartRounded/>,
    link: constants.RTE_METRICS,
  }, {
    label: 'Tutorials',
    icon: <Movie/>,
    link: constants.RTE_TUTORIALS,
  }
];

class MainSideBar extends React.Component {
  render() {
    const { classes, openSideBar, onSideBarClose, projectSelected } = this.props;
    const selectProject = 'Select a Project';
    const projectItem = (
      <ListItem button key={'project'}>
        <ListItemIcon><ListIconRounded/></ListItemIcon>
        <ListItemText primary={projectSelected ? projectSelected.name : selectProject}></ListItemText>
      </ListItem>
    );
    const projectSelectComponent = (<ProjectSelect closeSidebar={onSideBarClose} baseComponent={projectItem} projectList={[]}></ProjectSelect>)

    // create the list
    const sideList = (
      <div className={classes.list}>
        {/* if projectSelected is passed just show all items, otherwise just show the project view */}
        {projectSelected ? 
          <List>
            {projectSelectComponent}
            {<Divider/>}
            {SideBarItems.map((item) => (
              <Link key={item.link} to={item.link} style={{ textDecoration: 'none' }}>
                <ListItem onClick={onSideBarClose} button key={item.label}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItem>
              </Link>
            ))}
          </List>
        : 
          <List>
            {projectSelectComponent}
          </List>
        }
      </div>
    );

    return (
      <div>
        {/* <Button onClick={this.toggleDrawer('left', true)}>Open Left</Button> */}
        <Drawer open={openSideBar} onClose={onSideBarClose}>
          {sideList}
        </Drawer>
      </div>
    );
  }
}

MainSideBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MainSideBar);
