import React from 'react';
import CheckboxTable from '../common/CheckboxTable';
import { Dialog, DialogActions, DialogContent, Button } from '@material-ui/core'

class LocationDataSessionDialog extends React.Component {
  render() {
    const { onClose, open, onApply, sessions, defaultSelected, onDelete } = this.props;
    return (
      <Dialog
        open={open || false}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        {/* <DialogTitle id="form-dialog-title">Filter By Sessions</DialogTitle> */}
        <DialogContent>
          <CheckboxTable innerRef={r => this._tableRef = r } 
            data={sessions} 
            idField={'sessionId'} 
            getCellValue={(rowData, col) => (col.date ? new Date(rowData[col.id]).toLocaleString() : rowData[col.id]) || ""} 
            title={"Remove or Filter Data by Sessions"} 
            onDelete={(e) => onDelete(e, this._tableRef.getSelected())}
            defaultSelected={defaultSelected}
            columns={[
              { id: 'sessionId', numeric: true, disablePadding: true, label: 'Session ID' },
              { id: 'dateCreated', date: true, disablePadding: false, label: 'Date Created' },
              { id: 'nodeCount', numeric: true, diseablePadding: false, label: 'Number of Nodes' },
            ]}
          >
          </CheckboxTable>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={(e) => onApply(e, this._tableRef.getSelected()) } color="primary">
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default LocationDataSessionDialog;