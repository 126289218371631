import React, { Component } from 'react';
// import '../css/App.css'; // TODO import this once you have a style that you like
import { MuiThemeProvider } from '@material-ui/core/styles';
import muiTheme from './muiTheme';
import AppRoutes from './routes/AppRoutes';
import { AccountContext, DefaultContext } from './Context';
import constants from './utils/constants';
import cookies from './utils/cookieUtils';
import '../css/react-vis.css';
import projectService from './services/projectService';

// The way context works is that the provider componenet is in charge of updating values.
// Therefore, this class should be responsible for updating context, i.e. provide methods
// for componenets to use to update the context
class App extends Component {
  // need to use constructor to get access to the 'this' object when initializing state
  constructor(props) {
    super(props);
    // merge the new state, with the default
    let project = this.readSessionProject();
    this.state = {
      ...DefaultContext, 
      ...{setAccountContext: this.setAccountContext, setAuthenticated: this.setAuthenticated, setProject: this.setProject, project: project ? JSON.parse(project) : undefined}
    };
  };

  setAccountContext = (account, cb) => {
    console.log('setting account: ', account);
    if (account.lastProject) {
      projectService.getProject(account.lastProject)
      .then(res => {
        this.setState({account: account}, () => this.setProject(res.data, cb));
      })
      .catch(constants.BASE_ERROR_HANDLER);
    } else {
      this.setState({account: account}, () => {
        if (cb) {
          cb();
        }
      });
    }
  };

  setAuthenticated = (auth) => {
    console.log('setting account: ', auth);
    this.setState({authenticated: auth});
  };

  setProject = (project, cb) => {
    console.log('setting project: ', project)
    this.setState({project: project}, () => {
      if (cb) {
        cb();
      }
    });
    // attempt to save project in session storage
    this.setSessionProject(project);
  };

  readSessionProject = () => {
    let token = cookies.readCookie(constants.AUTH_COOKIE);
    if (token) {
      return sessionStorage.getItem(token);
    }
    return undefined;
  };

  setSessionProject = project => {
    let token = cookies.readCookie(constants.AUTH_COOKIE);
    if (token) {
      sessionStorage.setItem(token, JSON.stringify(project));
    }
    // update the last project, dont care about the res
    projectService.setLastProject(project.projectId);
  }

  render() {
    return (
        <div className="app-root-container">
          <MuiThemeProvider theme={muiTheme}>
            <AccountContext.Provider value={this.state}>
              <AppRoutes project={this.state.project} />
            </AccountContext.Provider>
          </MuiThemeProvider>
        </div>
    );
  }
}

export default App;
