import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { AutoSizer, Column, SortDirection, Table } from 'react-virtualized';
import { Paper, Grid, IconButton } from '@material-ui/core';
import DeleteRounded  from '@material-ui/icons/DeleteRounded';

const styles = theme => ({
  table: {
    fontFamily: theme.typography.fontFamily,
    '&:focus': {
      outline: 0.
    }
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box',
    '&:focus': {
      outline: 0.
    }
  },
  tableRow: {
    // cursor: 'pointer', // this is what causes the rows to flicker
  },
  selectedRow: {
    backgroundColor: theme.palette.grey[400],
  },
  tableRowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  tableCell: {
    flex: 1,
  },
  noClick: {
    cursor: 'initial',
  },
});

class ScrollableTable extends React.PureComponent {
  _selected = -1;

  onRowClickInterceptor = (e) => {
    const { onRowClick } = this.props;
    if (onRowClick) {
      this._selected = e.index;
      onRowClick(e);
    }
  }

  getRowClassName = ({ index }) => {
    const selected = index !== -1 && this._selected === index;
    const { classes, rowClassName, onRowClick } = this.props;
    return classNames(classes.tableRow, classes.flexContainer, rowClassName, {
      [classes.tableRowHover]: index !== -1 && onRowClick != null,
    }, {
      [classes.selectedRow]: selected,
    });
  };

  cellRenderer = ({ cellData, columnIndex = null }) => {
    const { columns, classes, rowHeight, onRowClick, onDeleteRow } = this.props;
    return (
      <TableCell
        component="div"
        className={classNames(classes.tableCell, classes.flexContainer, {
          [classes.noClick]: onRowClick == null,
        })}
        variant="body"
        style={{ height: rowHeight }}
        align={(columnIndex != null && columns[columnIndex].numeric) || false ? 'right' : 'left'}
      >
        {!onDeleteRow || (columnIndex !== columns.length - 1) ?
          cellData
        :
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={10}>
              {cellData}
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={this.onDeleteRowHandler(cellData)}>
                <DeleteRounded></DeleteRounded>
              </IconButton>
            </Grid>
          </Grid>
        }
      </TableCell>
    );
  };

  onDeleteRowHandler = (cellData) => (e) => {
    const { onDeleteRow } = this.props;
    if (onDeleteRow) {
      onDeleteRow(cellData, e);
    }
  }

  headerRenderer = ({ label, columnIndex, dataKey, sortBy, sortDirection }) => {
    const { headerHeight, columns, classes, sort } = this.props;
    const direction = {
      [SortDirection.ASC]: 'asc',
      [SortDirection.DESC]: 'desc',
    };

    const inner =
      !columns[columnIndex].disableSort && sort != null ? (
        <TableSortLabel active={dataKey === sortBy} direction={direction[sortDirection]}>
          {label}
        </TableSortLabel>
      ) : (
        label
      );

    return (
      <TableCell
        component="div"
        className={classNames(classes.tableCell, classes.flexContainer, classes.noClick)}
        variant="head"
        style={{ height: headerHeight }}
        align={columns[columnIndex].numeric || false ? 'right' : 'left'}
      >
        {inner}
      </TableCell>
    );
  };

  render() {
    const { noPaper, minHeight} = this.props;
    if (noPaper) {
      return (
        <div style={{height: '100%', width: '100%', border: '1px solid #D7DBDD'}}> 
          {this._render()}
        </div>
      )
    }
    return (
      <Paper style={{ minHeight: minHeight, width: '100%' }}>
        {this._render()}
      </Paper>
    );
  }

  _render = () => {
    const { classes, columns, minHeight, noPaper, onRowClick, ...tableProps} = this.props;
    return (
      <AutoSizer>
          {({ height, width }) => (
            <Table
              className={classes.table}
              height={height}
              width={width}
              onRowClick={this.onRowClickInterceptor}
              {...tableProps}
              rowClassName={this.getRowClassName}
            >
              {columns.map(({ cellContentRenderer = null, className, dataKey, colWidth, ...other }, index) => {
                let renderer;
                if (cellContentRenderer != null) {
                  renderer = cellRendererProps =>
                    this.cellRenderer({
                      cellData: cellContentRenderer(cellRendererProps),
                      columnIndex: index,
                    });
                } else {
                  renderer = this.cellRenderer;
                }

                return (
                  <Column
                    key={dataKey}
                    headerRenderer={headerProps =>
                      this.headerRenderer({
                        ...headerProps,
                        columnIndex: index,
                      })
                    }
                    className={classNames(classes.flexContainer, className)}
                    cellRenderer={renderer}
                    dataKey={dataKey}
                    width={colWidth || width/columns.length}
                    {...other}
                  />
                );
              })}
            </Table>
          )}
        </AutoSizer>
    );
  }
}

ScrollableTable.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      cellContentRenderer: PropTypes.func,
      dataKey: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
    }),
  ).isRequired,
  headerHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  rowClassName: PropTypes.string,
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]),
  sort: PropTypes.func,
};

ScrollableTable.defaultProps = {
  headerHeight: 56,
  rowHeight: 56,
  paperHeight: 112,
};

export default withStyles(styles)(ScrollableTable);