import React from 'react';
import chartUtils from '../../utils/chartUtils';
import { RadialChart, DiscreteColorLegend } from 'react-vis';
import { withStyles } from '@material-ui/core';

const styles = theme => ({
  legend: {
    position: 'absolute',
    top: 0,
    left: 0,
  }
});

class EmptyRadialChart extends React.Component {
  getData() {
    const chartData = [];
    const legendData = []
    for (let i = 0; i < 3; i++) {
      chartData.push({angle: 33, label: `Empty${i}`, color: chartUtils.getColorByPosition(i)});
      legendData.push({title: `Empty${i}`, color: chartUtils.getColorByPosition(i)});
    }
    return {chartData, legendData}
  };

  render() {
    const {chartData, legendData} = this.getData()
    const {chartWidth, chartHeight, legendWidth, legentHeight, classes} = this.props;
    return (
      <React.Fragment>
        <RadialChart
          data={chartData}
          colorType="literal"
          width={chartWidth}
          height={chartHeight}
        />
        <DiscreteColorLegend className={classes.legend} height={legentHeight} width={legendWidth} items={legendData} />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(EmptyRadialChart);