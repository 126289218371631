
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import MainSideBar from './MainSideBar';
import MenuIconRounded from '@material-ui/icons/MenuRounded';
import IconButton from '@material-ui/core/IconButton';
import Axios from 'axios';
import { AccountContext } from '../../Context';
import constants from '../../utils/constants';
import { withRouter } from 'react-router-dom';

const styles = {
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  logo: {
    width: '119px',
    height: '40px'
  },
  pushRight: {
    flex: 1,
    justifyContent: 'flex-start'
  },
  grow: {
    flexGrow: 1,
  },
  root: {
    height: '9vh',
    overflow: 'inherit',
  }
};

class MainTopBar extends React.Component {
  state = {
    openSideBar: false
  };

  toggleDrawer = (open) => () => {
    this.setState({
      openSideBar: open,
    });
  };

  logout = () => {
    Axios({
      method: constants.POST_METHOD,
      url: constants.API_LOGOUT,
      data: {},
      config: {
        headers: constants.CONTENT_TYPE.JSON
      }
    })
    .then(response => {
      if (response.status === 200) {
        sessionStorage.clear();
        window.location.reload();
      }
    }).catch(constants.BASE_ERROR_HANDLER);
  };

  render() {
    const { classes, project } = this.props;
    const { openSideBar } = this.state;
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton className={classes.menuButton} color="inherit" aria-label="Menu"
              onClick={this.toggleDrawer(true).bind(this)}>
              <MenuIconRounded />
            </IconButton>
            <MainSideBar projectSelected={project} openSideBar={openSideBar} onSideBarClose={this.toggleDrawer(false)}/>
            <div className={classes.pushRight}>
              <img alt="main-logo" src="/images/logo.png" className={[classes.logo]}/>
            </div>
            <div>
              {/* TODO replace this */}
              <Button color="inherit" onClick={this.logout}>Logout</Button>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }

}

MainTopBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

// work around for error with Context and withRouter(...)
// https://github.com/facebook/react/issues/14061
const Wrapped = withRouter(MainTopBar);
Wrapped.WrappedComponent.contextType = AccountContext;

export default withStyles(styles)(Wrapped);