import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import axios from 'axios';
import constants from '../../utils/constants';
import { withRouter } from 'react-router-dom';
import { AccountContext } from '../../Context';

const styles = theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
});

class SignUp extends React.Component {
  state = {
    username: '',
    password: '',
    confirmPassword: ''
  };

  login = (e) => {
    let { username, password } = this.state;
    if (!username || !password) {
      return;
    }
    console.log(this.context)
    const { setAccountContext, setAuthenticated } = this.context;
    let formData = new FormData();
    formData.append('username', username);
    formData.append('password', password);
    axios({
      method: 'POST',
      url: constants.API_LOGIN,
      data: formData,
      config: { 
        headers: {
          'Content-Type': 'multipart/form-data' 
        }
      }
    })
    .then((response) => {
      let { history } = this.props;
      if (response.status === 200) {
        setAuthenticated(true);
        setAccountContext(response.data, () => history.push(constants.RTE_LOCATIONS));
      }
    })
    .catch((response) => {
        if (response.status === 401) {
          alert("Unauthorized");
          return;
        }
        alert(response);
    });
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  formValidate = event => {
    event.preventDefault(event);
  };

  render() {
    const { classes } = this.props;
    const label = "Log In";
    return (
      <div>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h5">
          {label}
          </Typography>
          <form className={classes.form} action="/bm/signin" onSubmit={this.formValidate}  method="post">
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="username">Email Address</InputLabel>
              <Input id="username" name="username" autoComplete="username" autoFocus 
                value={this.state.username} onChange={this.handleChange('username')} required/>
            </FormControl>
            <FormControl margin="normal" required fullWidth>
              <InputLabel htmlFor="password">Password</InputLabel>
              <Input name="password" type="password" id="password"
                value={this.state.password} onChange={this.handleChange('password')} required/>
            </FormControl>
            {/* <Button variant="text" onClick={this.changePassword}>
              Change Password
            </Button> */}
            <Button
              type="submit" fullWidth variant="contained" 
              onClick={this.login}
              color="primary" className={classes.submit}>
              {label}
            </Button>
          </form>
        </Paper>
      </div>
    );
  }
}

// work around for error with Context and withRouter(...)
// https://github.com/facebook/react/issues/14061
const Wrapped = withRouter(SignUp);
Wrapped.WrappedComponent.contextType = AccountContext;

export default withStyles(styles)(Wrapped);