import React from 'react';
import { Grid, withStyles } from '@material-ui/core';

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
  }
});

/*
  Expected props:
    gridData: [{...}] -> the data that will populate the grid
    cardDataKey: string -> the name of the prop to pass the data into the gridCompoment
    gridComponent: Component -> the component that will receive the grid data to create the view
    gridComponentChildren: [Component] -> children passed into the gridComponent
*/
class ScalableGrid extends React.Component {
  render() {
    const { classes, gridData, gridComponent, cardDataKey, gridComponentChildren} = this.props;
    return (
      <div className={classes.root}>
        <Grid container spacing={24}>
          {gridData.map((dataItem,i) => (
            <Grid key={i} item xs={12} sm={6} md={4}>
              {React.cloneElement(gridComponent, {[cardDataKey]: dataItem}, gridComponentChildren)}
            </Grid>
          ))}
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(ScalableGrid);