import React, { Component } from 'react';
import CreatableSelect from 'react-select/creatable';
import { withStyles } from '@material-ui/core/styles';
import { Switch, FormControlLabel, Grid, Typography } from '@material-ui/core';

const styles = theme => ({
  root: {
    flexGrow: 1,
    maxWidth: 480,
    minWidth: 480,
    height: '100%',
  },
  paper: {
    padding: theme.spacing.unit * 2,
  },
  fab: {
    margin: theme.spacing.unit,
    float: 'right',
  }
})

class CriteriaPanel extends Component {
  multivaluedChanged = e => {
    let multi = e.currentTarget.checked;
    let { currentCriteria, setCurrentCriteria } = this.props;
    let newCritera = Object.assign({}, currentCriteria);
    newCritera.multivalued = multi;
    setCurrentCriteria(newCritera, true);
  };
  
  handleChange = (newValue, actionMeta) => {
    if (actionMeta.action === 'select-option') {
      let { setCurrentCriteria } = this.props;
      setCurrentCriteria(newValue.value);
    }
  };

  extractOptionsFromCriteria = () => {
    const { criterias, currentCriteria } = this.props;
    let res = {criteriaOptions:[], selectedCriteria: undefined};
    if (criterias) {
      res.criteriaOptions = criterias.map(x => ({value: x.criteriaId, label: x.name}));
    }
    if (currentCriteria) {
      res.selectedCriteria = {value: currentCriteria.criteriaId, label: currentCriteria.name};
    }
    return res;
  };

  onCriteriaCreate = criteriaName => {
    const { addCriteriaHandler } = this.props;
    if (addCriteriaHandler) {
      addCriteriaHandler(criteriaName);
    }
  };

  render() {
    const { currentCriteria } = this.props;
    let { criteriaOptions, selectedCriteria } = this.extractOptionsFromCriteria();
    let checked = (currentCriteria && currentCriteria.multivalued) || false;
    return (
      <React.Fragment>
        <Typography style={{marginBottom:'10px'}} variant="h6">Name</Typography>
        <Grid container spacing={16} direction="row" justify="flex-start" alignItems="flex-start">
          <Grid item xs={12} md={8}>
            <CreatableSelect 
              key={`cp_c_key_${JSON.stringify(selectedCriteria)}`}
              onChange={this.handleChange}
              // onInputChange={this.handleInputChange}
              options={criteriaOptions || []}
              value={selectedCriteria}
              onCreateOption={this.onCriteriaCreate}>
            </CreatableSelect>
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControlLabel 
              control={<Switch checked={checked} color="secondary" onChange={this.multivaluedChanged} value="multivalued" />} 
              label="Multivalued" />
          </Grid>
        </Grid>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(CriteriaPanel);