import React from 'react';

import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines
} from 'react-vis';

export default class EmptyXYPlot extends React.Component {
  render() {

    return (
      <XYPlot
        dontCheckIfEmpty
        xDomain={[0, 3]}
        yDomain={[10, 3]}
        {...this.props}>
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis
          hideLine
          title="Empty Chart"
          tickFormat={v => `${v}!`}
          tickValues={[1, 1.5, 2, 3]} />
        <YAxis hideTicks/>
      </XYPlot>
    );
  }
}