const colors = ["DarkGreen","Maroon","BlueViolet","Chartreuse","Indigo ","LawnGreen","MidnightBlue","DeepSkyBlue","DarkRed","MediumTurquoise","DimGray","LightGreen","DeepSkyBlue","CadetBlue","DarkSlateGray","DeepSkyBlue","DarkSlateGray","DarkSlateBlue","DarkTurquoise","DarkGreen"]

const getColorByPosition = (pos) => pos >= 20 ? "black" : colors[pos];
const roundToDecimal = (number, places) => {
  return +(Math.round(number + "e+" + places)  + "e-" + places);
}

export default {
  getColorByPosition,
  roundToDecimal,
};