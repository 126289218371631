import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Paper, Typography, Button } from '@material-ui/core';
import CriteriaValuePanel from './CriteriaValuePanel';
import CriteriaPanel from './CriteriaPanel';
import axios from 'axios';
import constants from '../../utils/constants';
import { AccountContext } from '../../Context';
import PageBase from '../common/PageBase';
import criteriaService from '../../services/criteriaService';
import res from '../../utils/res';

const styles = theme => ({
  // add syles
  root: {
    flexGrow: 1,
    height: '100%',
  },
  paper: {
    padding: theme.spacing.unit * 2,
  },
  buttons: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  deleteButton: {
    backgroundColor: 'red',
  }
});

class CriteriaPage extends React.Component {
  _criteriaMap = {};    
  
  state = {
    criterias: [],
    currentCriteria: null,
  };

  addCriteriaValueHandler = (value) => {
    let { project } = this.context;
    let { currentCriteria } = this.state;
    if (!currentCriteria || !currentCriteria.criteriaId) {
      return;
    }

    let criteriaValue = {value: value, criteriaId: currentCriteria.criteriaId};
    axios({
      method: constants.POST_METHOD,
      url: constants.API_CRITERIA_ADD_VALUE.replace(':projectId', project.projectId).replace(':criteriaId', currentCriteria.criteriaId),
      config: {
        headers: {
          'Content-Type': 'application/json'
        }
      },
      data: criteriaValue,
    }).then(response => {
      if (response.status === 200) {
        this.loadCriterias(currentCriteria.criteriaId)
      }
    }).catch(constants.BASE_ERROR_HANDLER);
  };

  addCriteriaHandler = (criteriaName) => {
    let { project } = this.context;
    let criteria = {name: criteriaName, multivalued: false};
    axios({
      method: constants.POST_METHOD,
      url: constants.API_CRITERIAS.replace(':projectId', project.projectId),
      config: {
        headers: {
          'Content-Type': 'application/json'
        }
      },
      data: criteria,
    }).then(response => {
      if (response.status === 200) {
        this.loadCriterias(response.data.criteriaId)
      }
    }).catch(constants.BASE_ERROR_HANDLER);
  };

  deleteCriteriaHandler = (e) => {
    const { currentCriteria } = this.state;
    const { openAlert } = this.props;
    const { project } = this.context;
    if (currentCriteria && project) {
      let delCriteria = () => {
        criteriaService.deleteCriteria(project.projectId, currentCriteria.criteriaId)
        .then(response => {
          if (response.status === 204) {
            this.setState({currentCriteria: null}, () => this.loadCriterias());
          }
        })
        .catch(constants.BASE_ERROR_HANDLER)
      };

      openAlert({
        contentText: res.CONFIRM_DELETE_CRITERIA_MSG.replace(':name', currentCriteria.name),
        okText: "Confirm",
        onOk: delCriteria,
      });
    }
  };

  deleteCriteriaValueHandler = (value, e) => {
    const { project } = this.context;
    const { openAlert } = this.props;
    const { currentCriteria } = this.state;

    if (currentCriteria && project && value) {
      // find the id for the value      
      let cv = currentCriteria.criteriaValues.find(v => v.value == value);
      if (cv) {
        let delCV = () => {
          criteriaService.deleteCriteriaValues(project.projectId, currentCriteria.criteriaId, [cv.criteriaValueId])
          .then(response => {
            if (response.status === 204) {
              this.loadCriterias(currentCriteria.criteriaId, true)
            }
          }).catch(constants.BASE_ERROR_HANDLER);
        }

        openAlert({
          contentText: res.CONFIRM_DELETE_CRITERIA_VALUE_MSG.replace(':value', value).replace(':name', currentCriteria.name),
          okText: 'Confirm',
          onOk: delCV
        });
      }
    }
  };

  saveCriteria = (criteria) => {
    const { project } = this.context;
    if (!criteria || !criteria.criteriaId) {
      return;
    }
    // update the current project and trigger a refresh of criteria
    axios({
      method: constants.PUT_METHOD,
      url: constants.API_CRITERIA.replace(':projectId', project.projectId).replace(':criteriaId', criteria.criteriaId),
      config: {
        headers: {
          'Content-Type': 'application/json',
        }
      },
      data: criteria,
    }).then(response => {
      if (response.status === 200) {
        this.loadCriterias(response.data.criteriaId);
      }
    }).catch(constants.BASE_ERROR_HANDLER);
  }

  loadCriterias = (criteriaIdAfterLoad) => {
    let { project } = this.context;
    if (!project || !project.projectId) {
      return;
    }
    axios({
      method: 'GET',
      url: constants.API_CRITERIAS.replace(':projectId', project.projectId),
      config: {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    }).then(response => {
      if (response.status === 200) {
        if (response.data && response.data.length > 0) {
          this._criteriaMap = response.data.reduce((map, obj) => {
            map[obj.criteriaId] = obj;
            return map;
          }, {});
        }
        this.setState(() => ({
          criterias: response.data || [],
          currentCriteria: criteriaIdAfterLoad ? this._criteriaMap[criteriaIdAfterLoad] : null,
        }));
      }
    }).catch(constants.BASE_ERROR_HANDLER)
  };

  componentDidMount() {
    this.loadCriterias();
  };

  setCurrentCriteria = (criteria, save) => {
    if (Number.isInteger(criteria)) {
      criteria = this._criteriaMap[criteria];
    }
    if (criteria && criteria.criteriaId) {
      if (save) {
        this.saveCriteria(criteria);
        return;
      }
      this.setState({currentCriteria: criteria});
    }
  };

  render() {
    const { classes } = this.props;
    const { criterias, currentCriteria } = this.state;
    return (
      <React.Fragment>
        <PageBase header={"Criteria"}>
          <Paper className={classes.paper}>
              <Grid container spacing={24} direction="row" justify="flex-start" alignItems="flex-start">
                <Grid item xs={5}>
                  <CriteriaPanel addCriteriaHandler={this.addCriteriaHandler} setCurrentCriteria={this.setCurrentCriteria} criterias={criterias} currentCriteria={currentCriteria} ></CriteriaPanel>
                  {/* TODO replace this with a tooltip */}
                  <Typography variant="body1">To create new criteria, you can type in the dropdown above and select "Create"</Typography>
                  <Grid container direction="column-reverse" justify="space-evenly" alignItems="baseline">
                    <Grid item xs={12}>
                      <Button variant="contained" disabled={!(!!currentCriteria)} size="medium" onClick={this.deleteCriteriaHandler}>Delete</Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid style={{height: "100%"}} item xs={7}>
                  <CriteriaValuePanel 
                    disableAddButton={!!!(currentCriteria)} 
                    criteriaValues={currentCriteria ? currentCriteria.criteriaValues : []} 
                    addCriteriaValueHandler={this.addCriteriaValueHandler} 
                    onDeleteCriteriaValueHandler={this.deleteCriteriaValueHandler}
                  ></CriteriaValuePanel>
                </Grid>
              </Grid>
            </Paper>
        </PageBase>
      </React.Fragment>
    );
  }
}
CriteriaPage.contextType = AccountContext;

export default withStyles(styles)(CriteriaPage);