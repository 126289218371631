import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogActions, DialogContent, Button, Grid, TextField, DialogTitle, Typography } from '@material-ui/core';
import ScrollableTable from '../common/ScrollableTable';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';

const styles = theme => ({
  fab: {
    margin: theme.spacing.unit, 
  }
})

class CriteriaValuePanel extends Component {
  state = {
    openDialog: false,
    newCriteriaValue: undefined,
  };

  openAddCriteriaDialog = (open) => () => {
    this.setState({openDialog: open});
  };

  handleChange = (e) => {
    this.setState({
      newCriteriaValue: e.target.value
    });
  }
  
  addCriteriaValue = e => {
    const { newCriteriaValue } = this.state;
    if (!!!newCriteriaValue) {
      alert('you must input a criteria value');
      return;
    }
    
    // so if there is a new critera invoke the handler
    const { addCriteriaValueHandler } = this.props;
    if (addCriteriaValueHandler) {
      addCriteriaValueHandler(newCriteriaValue);
      this.openAddCriteriaDialog(false)();
    }
  };

  onDeleteRow = (cellData, e) => {
    const { onDeleteCriteriaValueHandler } = this.props;
    if (onDeleteCriteriaValueHandler) {
      onDeleteCriteriaValueHandler(cellData, e);
    }
  };

  render() {
    const { classes, criteriaValues, disableAddButton } = this.props;
    return (
      <React.Fragment>
        <Grid container justify="space-between">
          <Grid item xs={9}>
            <Typography style={{marginBottom:'10px'}} variant="h6">Values</Typography>
          </Grid>
          <Grid item xs={3}>
            <Fab onClick={this.openAddCriteriaDialog(true)} style={{marginTop: 0, float: 'right'}} size="small" color="primary" aria-label="Add" className={classes.fab} disabled={disableAddButton}>
              <AddIcon />
            </Fab>
          </Grid>
        </Grid>
        <Grid container spacing={16} direction="row" justify="flex-start" alignItems="flex-start">
          <Grid item xs={12}>
            <ScrollableTable
              minHeight={280}
              rowCount={(criteriaValues && criteriaValues.length) || 0}
              rowGetter={({ index }) => criteriaValues[index]}
              columns={[
                {
                  flexGrow: 1,
                  label: 'Value',
                  dataKey: 'value',
                }]
              }
              onDeleteRow={this.onDeleteRow}
            />
          </Grid>
          <Grid item xs={2}>
          </Grid>
        </Grid>
        <Dialog
          style={{minWidth: '250px'}}
          open={this.state.openDialog}
          onClose={this.openAddCriteriaDialog(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Add Criteria Value</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="criteriaValue"
              label="Criteria Value"
              onChange={this.handleChange}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.openAddCriteriaDialog(false)} color="secondary">
              Cancel
            </Button>
            <Button onClick={this.addCriteriaValue} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(CriteriaValuePanel);