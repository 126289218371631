import React from 'react';
import { withStyles, Card, CardContent, CardMedia, CardActionArea, Typography, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import params from '../../utils/urlUtils';
import constants from '../../utils/constants';


const styles = theme => ({
  root: {
    maxWidth: 400,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
  },
  meda: {
    objectFit: 'cover',
  }
});

class LocationGridCard extends React.Component {
  startSession = () => {
    // the cardData props should be a location
    let location = this.props.cardData;
    let { history } = this.props;
    if (location.locationId) {
      history.push(params.url(constants.RTE_SESSION, {id: location.locationId}))
    }
  }

  viewData = () => {
    let location = this.props.cardData;
    let { history } = this.props;
    if (location.locationId) {
      history.push(params.url(constants.RTE_LOCATION_DATA, {id: location.locationId}))
    }
  }

  onEdit = (e) => {
    const { onEdit, cardData } = this.props;
    if (onEdit) {
      onEdit(cardData);
    }
  }

  render() {
    const { classes, cardData } = this.props;
    return (
      <Card className={classes.root}>
        <CardActionArea disableRipple disableTouchRipple onClick={this.onEdit}>
          {cardData.overlayUrl ? 
            <CardMedia
              component="img"
              alt={cardData.name}
              className={classes.media}
              height={cardData.height ? cardData.height : "140"}
              image={cardData.overlayUrl}
              title={cardData.name}
            />
          :
            <Typography style={{height: cardData.height ? cardData.height : "140", marginLeft: 10}} variant="h5">No image</Typography>
          }
        </CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h5">
            {cardData.name}
          </Typography>
          <Typography component="p">
            {"Description: " + (cardData.description || "")}
          </Typography>
          <Button onClick={this.startSession} style={{float: "right", marginTop: 8, marginBottom: 8, color:"green"}} variant="text" size="small" color="primary">Start Session</Button>
          <Button onClick={this.viewData} style={{float: "right", marginTop: 8, marginBottom: 8, color:"black"}} variant="text" size="small">View Data</Button>
        </CardContent>
      </Card>
    )
  }
}

export default withStyles(styles)(withRouter(LocationGridCard));