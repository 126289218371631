import React from 'react';
import constants from '../../utils/constants';
import { AccountContext } from '../../Context';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, Fab } from '@material-ui/core';
import ScalableGrid from '../common/ScalableGrid';
import LocationGridCard from './LocationGridCard';
import AddIcon from '@material-ui/icons/Add';
import LocationCreateEdit from './LocationCreateEdit';
import locationService from '../../services/locationService';
import UIBlocker from '../common/UIBlocker';
import PageBase from '../common/PageBase';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
  }, 
  fab: {
    margin: theme.spacing.unit, 
  }
});

class LocationPage extends React.Component {
  // TODO come up with better way to determine if the project changed
  // this is how we determine if the current project has changed
  _project = undefined;
  state= {
    locations: [],
    currentLocation: undefined,
    mode: undefined,
    block: false,
  };

  addFabHandler = (e) => {
    this.setState({mode: constants.MODES.CREATE})
  };

  onEdit = (location, e) => {
    if (location && location.locationId) {
      this.setState({currentLocation: location, mode: constants.MODES.EDIT});
    }
  }

  componentDidMount() {
    if (this._project === undefined || (this.props.project.projectId !== this._project.projectId)) {
      // first set it, the load the locations
      this._project = this.props.project;
      this.loadLocations()
    }
  }

  componentDidUpdate() {
    if (this.props.project === undefined) {
      // do nothing 
      return;
    }
    if (this._project === undefined || (this.props.project.projectId !== this._project.projectId)) {
      // first set it, the load the locations
      this._project = this.props.project;
      this.loadLocations()
    }
  }

  saveAndUpload = (location, file) => {
    const { project } = this.props;

    if (project) {
      this.setState({block: true}, () => {
        locationService.save(project.projectId, location)
        .then(resLocation => {
          if (resLocation.status === 200) {
            if (!file) {
              this.loadLocations(true);
              return;
            }
            
            locationService.uploadFloorplan(project.projectId, resLocation.data.locationId, file)
            .then(resFloorplan => {
              if (resFloorplan.status === 200) {
                this.loadLocations(true);
              }
            }).catch(constants.BASE_ERROR_HANDLER);
          }
        }).catch(constants.BASE_ERROR_HANDLER);
      });
    }
  };

  loadLocations = (reset) => {
    const { project } = this.props;

    locationService.getLocations(project.projectId)
    .then(response => {
      if (response.status === 200) {
        if (reset) {
          this.setState({
            locations: response.data,
            mode: undefined,
            block: false
          });
        } else {
          this.setState({locations: response.data, block: false})
        }
      }
    })
    .catch(constants.BASE_ERROR_HANDLER);
  };

  setModeHandler = (mode) => () => {
    this.setState({
      mode: mode
    });
  }

  render() {
    const { classes } = this.props;
    const { block } = this.state;
    return(
      <React.Fragment>
        <UIBlocker block={block}></UIBlocker>
        <PageBase>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start">
            <Grid item xs={12} style={{paddingTop: 10, paddingBottom: 10}}>
              <Typography style={{display: 'inline'}} variant="h4">Locations</Typography>
              <div  style={{float: 'right', clear: 'left'}}>
                <Fab disabled={!(this.state.mode === undefined)} onClick={this.addFabHandler} size="small" color="primary" aria-label="Add" className={classes.fab}>
                  <AddIcon />
                </Fab>
              </div>
            </Grid>
            <Grid item xs={12}>
              {this.state.mode === constants.MODES.CREATE || this.state.mode == constants.MODES.EDIT ?
                <LocationCreateEdit saveAndUpload={this.saveAndUpload} onFileUpload={this.onFileUpload} location={this.state.currentLocation} onCancel={this.setModeHandler(undefined)}></LocationCreateEdit>
              :
                <ScalableGrid gridData={this.state.locations} gridComponent={<LocationGridCard onEdit={this.onEdit} />} cardDataKey={'cardData'}></ScalableGrid>                
              }
            </Grid>
          </Grid>
        </PageBase>
      </React.Fragment>
    )
  }
};

LocationPage.contextType = AccountContext;

export default withStyles(styles)(LocationPage);