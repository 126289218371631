
// method to replace parameter keys in a url resource string, assumes
// parameters follow the colon format (:param)
const url = (str, params) => {
  if(str && params) {
    str = str.replace(/:[a-zA-Z0-9]*/g, function(match) {
      return params[match.substr(1)];
    });
  }
  return str;
}

export default {
  url
};