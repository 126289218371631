import React from 'react';
import { AccountContext } from '../../Context';
import { Grid, Paper, FormControl, InputLabel, Select, MenuItem, Input, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PageBase from '../common/PageBase';
import { VerticalBarSeries, XYPlot, VerticalGridLines, XAxis, YAxis, HorizontalGridLines, DiscreteColorLegend, RadialChart} from 'react-vis';
import criteriaService from '../../services/criteriaService';
import constants from '../../utils/constants';
import { AutoSizer } from 'react-virtualized';
import locationService from '../../services/locationService';
import Axios from 'axios';
import UIBlocker from '../common/UIBlocker';
import chartUtils from '../../utils/chartUtils';
import EmptyXYPlot from '../common/EmptyXYPlot';
import EmptyRadialChart from '../common/EmptyRadialChart';
import Map from '../common/Map';
import projectService from '../../services/projectService';
import ScrollableTable from '../common/ScrollableTable';
import nodeService from '../../services/nodeService';
import ChipMultiSelect from '../common/ChipMultiSelect';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paperBase: {
    width: '100%',
  },
  paperLarge: {
    minHeight: '300px',
    height: '100%',
    width: '100%',
    marginBottom: '22px',
    paddingTop: '1px',
  }, 
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 240,
  },
  legend: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  gridColumnWithChart: {
    minHeight: MIN_CHART_HEIGHT+1,
  },
});
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MIN_CHART_HEIGHT = 350;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const CHART_TYPES = {
  BAR: "Bar",
  PIE:  "Pie",
};

class MetricsPage extends React.Component {
  state = {
    locations: [],
    selectedLocation: undefined,
    selectedLocations: [],
    selectedLocationIds: [],
    chartType: CHART_TYPES.BAR,
    criterias: [],
    selectedCriteriaId: undefined,
    locationNodes: [],
    block: false,
    nodeCounts: undefined,
    barChartData: [],
    pieChartData: [],
    criteriaDataGrid: [],
    projectSummary: {
      totalNodes: 0,
      totalSessions: 0,
      locationSummary:[],
    },
  };

  componentDidMount() {
    let { project } = this.context;
    this.setState({block: true}, () => {
      Axios.all([
        criteriaService.getCriterias(project.projectId),
        locationService.getLocations(project.projectId),
        projectService.getProjectSummary(project.projectId),
      ]).then(Axios.spread((criteriaRes, locationRes, summaryRes) => {
        if (criteriaRes.status === 200 && locationRes.status === 200 && summaryRes.status === 200) {
          this.setState({criterias: criteriaRes.data, locations: locationRes.data, projectSummary: summaryRes.data})
        }
      })).catch(constants.BASE_ERROR_HANDLER)
      .then(() => this.setState({block: false}));
    });
  };

  selectedLocationChange = (e) => {
    if (e && e.target) {
      // once a valid location is selected, we need to fetch the location node data and then render the charts
      const locations = e.target.value;
      this.setState({selectedLocations: locations}, () => this.fetchLocationsNodes());
    }
  };

  removeSelectedLocation = (loc, e) => {
    const { selectedLocations } = this.state;
    const newLocations = selectedLocations.filter(l => l.locationId !== loc.locationId)
    this.setState({selectedLocations: newLocations || []}, () => this.fetchLocationsNodes());
  };

  renderChartControls = () => {
    const { classes } = this.props;
    const { selectedLocation, criterias, selectedCriteriaId, chartType } = this.state;
    if (!selectedLocation) {
      return null;
    }
    return (
      <React.Fragment>
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="select-chart-type">Chart Type:</InputLabel>
          <Select
            value={chartType}
            onChange={this.chartTypeChanged}
            input={<Input id="select-chart-type" />}
            MenuProps={MenuProps}
          >
            {Object.keys(CHART_TYPES).map(type => (
                <MenuItem key={CHART_TYPES[type]} value={CHART_TYPES[type]}>
                  {CHART_TYPES[type]}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl className={classes.formControl}>
            <InputLabel htmlFor="select-criteria">Criteria:</InputLabel>
            <Select
              value={selectedCriteriaId || ''}
              onChange={this.criteriaValueChanged}
              input={<Input id="select-criteria" />}
              MenuProps={MenuProps}
            >
              {criterias.map(criteria => (
                  <MenuItem key={criteria.criteriaId} value={criteria.criteriaId}>
                    {criteria.name}
                  </MenuItem>
                ))}
            </Select>
        </FormControl> 
      </React.Fragment>
    );
  };

  renderLocationBreakDownChart = () => {
    const { classes } = this.props;
    const { chartType, barChartData, pieChartData } = this.state;
    let legendData = this._getLegendData(pieChartData);
    let chart = null;
    const heightCalc = (height , minHegiht) => (minHegiht > height ? minHegiht : height);    
    switch (chartType) {
      case CHART_TYPES.BAR:
          chart = (
            <AutoSizer>
              {({width, height}) => {
                height = heightCalc(height, MIN_CHART_HEIGHT);
                if (barChartData && barChartData.length == 0) {
                  return (<EmptyXYPlot height={height} width={width}></EmptyXYPlot>);
                }
                return (
                  <XYPlot xType="ordinal" width={width} height={height}>
                    <VerticalGridLines />
                    <HorizontalGridLines />
                    <XAxis />
                    <YAxis />
                    <VerticalBarSeries data={barChartData} />
                  </XYPlot>
                );
              }}
            </AutoSizer>
          )
        break;
      case CHART_TYPES.PIE:
        chart = (
          <AutoSizer>
            {({width, height}) => {
              height = heightCalc(height, MIN_CHART_HEIGHT);
              if (pieChartData && pieChartData.length == 0) {
                return (<EmptyRadialChart chartWidth={width} chartHeight={height} legendHeight={height} legendWidth={200}></EmptyRadialChart>);
              }
              return (
                <React.Fragment>
                  <RadialChart
                    data={pieChartData}
                    width={width}
                    height={height}
                    colorType="literal"
                  />
                  <DiscreteColorLegend className={classes.legend} height={height} width={200} items={legendData} />
                </React.Fragment>
              )
            }}
          </AutoSizer>
        )
        break;
    }
    return chart;
  };

  renderSummaryCard = () => {
    const { projectSummary } = this.state;
    const variant = "h6";
    return (
      <React.Fragment>
        <Typography variant={variant} gutterBottom>
          {`Total Nodes: ${projectSummary.totalNodes}`}
        </Typography>
        <Typography variant={variant} gutterBottom>
          {`Total Sessions: ${projectSummary.totalSessions}`}
        </Typography>
        <Typography variant={variant} gutterBottom>
          {`Total Data Usage: N/A`}
        </Typography>
      </React.Fragment>
    )
  };

  renderLocationBreakdown = () => {
    const { classes } = this.props;
    const { selectedLocations, locationNodes, chartType, selectedCriteriaId, criterias, criteriaDataGrid} = this.state;
    if (!selectedLocations || selectedLocations.length <= 0) {
      return (
        <React.Fragment>
          <Grid item xs={12} style={{textAlign: 'center'}}>
            <Typography variant="h6" gutterBottom>Please select a location to view the location breakdown</Typography> 
          </Grid>
        </React.Fragment>
      );
    };
    let mapComp = null;
    if (selectedLocations.length === 1) {
      mapComp = (
        <Grid item xs={12}>
          <Map 
            showHeatMaps={true} 
            markers={locationNodes}
            mapElement={<div style={{height: '80%', minHeight: '540px'}} />} 
            zoomLimit={15} 
            floorplanUrl={selectedLocations.length > 0 && selectedLocations[0].overlayUrl}
          ></Map>
        </Grid>
      );
    }

    return (
      <React.Fragment>
        {mapComp}
        <Grid item xs={12}>
          <Typography variant="subheading" className={classes.formControl} gutterBottom>Criteria Overview</Typography>
          <FormControl className={classes.formControl}>
            <InputLabel htmlFor="select-chart-type">Chart Type:</InputLabel>
            <Select
              value={chartType}
              onChange={this.chartTypeChanged}
              input={<Input id="select-chart-type" />}
              MenuProps={MenuProps}
            >
              {Object.keys(CHART_TYPES).map(type => (
                  <MenuItem key={CHART_TYPES[type]} value={CHART_TYPES[type]}>
                    {CHART_TYPES[type]}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
              <InputLabel htmlFor="select-criteria">Criteria:</InputLabel>
              <Select
                value={selectedCriteriaId || ''}
                onChange={this.criteriaValueChanged}
                input={<Input id="select-criteria" />}
                MenuProps={MenuProps}
              >
                {criterias.map(criteria => (
                    <MenuItem key={criteria.criteriaId} value={criteria.criteriaId}>
                      {criteria.name}
                    </MenuItem>
                  ))}
              </Select>
          </FormControl> 
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row">  
            <Grid item xs={12} md={6} className={classes.gridColumnWithChart}>
                <ScrollableTable
                minHeight={MIN_CHART_HEIGHT}
                rowCount={criteriaDataGrid.length}
                rowGetter={({ index }) => criteriaDataGrid[index]}
                columns={this._getCriteriaGridColumns()}
                noPaper
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.gridColumnWithChart}>
              {this.renderLocationBreakDownChart()}
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };
  
  render() {
    const { classes } = this.props;
    const { locations, block, selectedLocation, projectSummary, selectedLocations } = this.state;
    const locationNodeData = projectSummary.locationSummary;
    return (
      <React.Fragment>
        <UIBlocker block={block}></UIBlocker>
        <PageBase header={"Metrics"}>
          <Grid container className={classes.root} direction="row">
            <Grid item xs={12}>
              <Paper className={classes.paperBase}>
                <div style={{marginLeft: '12px'}}>
                  {this.renderSummaryCard()}
                </div>
              </Paper>
            </Grid>
            <Grid item xs={12} style={{marginBottom: '10px'}}> 
              <Paper className={classes.paperLarge}>
                <Typography className={classes.formControl}  variant="h5" gutterBottom>Node Breakdown</Typography>
                <Grid container justify="flex-start" direction="row" spacing={8}>
                  <Grid item md={6} xs={12} className={classes.gridColumnWithChart}>
                    <ScrollableTable
                      minHeight={MIN_CHART_HEIGHT}
                      rowCount={locationNodeData.length}
                      rowGetter={({ index }) => locationNodeData[index]}
                      columns={this._getLocationSummaryColumns()}
                      noPaper
                    />
                  </Grid>
                  <Grid item md={6} xs={12} className={classes.gridColumnWithChart}>
                    <AutoSizer>
                      {({width}) => (
                        locationNodeData && locationNodeData.length == 0 ? <EmptyXYPlot height={MIN_CHART_HEIGHT} width={width}></EmptyXYPlot> :
                        <XYPlot xType="ordinal" width={width} height={MIN_CHART_HEIGHT}>
                          <VerticalGridLines />
                          <HorizontalGridLines />
                          <XAxis />
                          <YAxis />
                          <VerticalBarSeries data={this._convertLocationSummaryToBarChart(locationNodeData)[0]} />
                          <VerticalBarSeries data={this._convertLocationSummaryToBarChart(locationNodeData)[1]} />
                        </XYPlot>
                      )}
                    </AutoSizer>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} style={{marginBottom: '10px'}}>
              <Paper className={classes.paperLarge}>
              <Typography className={classes.formControl} variant="h5">Location Breakdown</Typography>
                <Grid container direction="row" justify="space-between" alignItems="center">
                  <Grid item xs={12}>
                    <ChipMultiSelect
                      data={locations}
                      idProp="locationId"
                      label="name"
                      title="Location(s):"
                      value={selectedLocations}
                      onDelete={this.removeSelectedLocation}
                      handleChange={this.selectedLocationChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div>
                      <Typography style={{marginLeft: '12px', marginTop:'4px'}}variant="caption" gutterBottom>{"* Selecting more than one location will hide the heatmap"}</Typography>
                    </div>
                  </Grid>
                  {this.renderLocationBreakdown()}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </PageBase>
      </React.Fragment>
    );
  };

  chartTypeChanged = (e) => {
    if (e && e.target) {
      this.setState({chartType: e.target.value})
    }
  };

  criteriaValueChanged = (e) => {
    if (e && e.target) {
      const { nodeCounts, criterias } = this.state;
      if (nodeCounts && criterias) {
        const { pieChartData, barChartData, criteriaDataGrid } = this._getChartData(criterias, nodeCounts, e.target.value);
        this.setState({selectedCriteriaId: e.target.value, pieChartData, barChartData, criteriaDataGrid});
      } else {
        this.setState({selectedCriteriaId: e.target.value});
      }
    }
  };

  fetchLocationsNodes = () => {
    const { project } = this.context;
    const { criterias, selectedCriteriaId, selectedLocations } = this.state;
    let selectedLocationIds = selectedLocations.map(x => x.locationId);
    if (selectedLocations.length === 0) {
      return;
    }
    this.setState({block: true}, () => {
      Axios.all([
        criteriaService.getCriteriaNodeCounts(project.projectId, selectedLocationIds),
        nodeService.getLocationNodes(project.projectId, selectedLocationIds.length > 0 ? selectedLocationIds[0] : selectedLocationIds),        
      ]).then(Axios.spread((criteriaNodCountsRes, locationNodesRes) => {
        if (criteriaNodCountsRes.status === 200 && locationNodesRes.status === 200) {
          // add the node counts to selected location for ease of use
          const newState = {
            nodeCounts: criteriaNodCountsRes.data,
            locationNodes: locationNodesRes.data
          }
          let currentCriteriaId = selectedCriteriaId;
          if (!currentCriteriaId && criterias && criterias.length > 0) {
            currentCriteriaId = criterias[0].criteriaId
            newState.selectedCriteriaId = currentCriteriaId;
          }
          if (currentCriteriaId) {
            const { barChartData, pieChartData, criteriaDataGrid } = this._getChartData(criterias, criteriaNodCountsRes.data, currentCriteriaId);
            newState.barChartData = barChartData;
            newState.pieChartData = pieChartData;
            newState.criteriaDataGrid = criteriaDataGrid;
          }
          this.setState(newState)
        }
      }))
      .catch(constants.BASE_ERROR_HANDLER)
      .then(() => this.setState({block: false}));
    })
  };

  _convertLocationSummaryToBarChart(locationSummary) {
    if (!locationSummary) {
      return [];
    }
    let res = [
      [],[]
    ];
    for (let ls of locationSummary) {
      res[0].push({x: ls.name, y: ls.totalNodes});
      res[1].push({x: ls.name, y: ls.totalSessions});
    }
    return res;
  }

  _getLocationSummaryColumns() {
    return [
      {
        width: 1500,
        flexGrow: 1,
        label: 'Location Name',
        dataKey: 'name',
      },
      {
        width: 1500,
        flexGrow: 1,
        label: 'Total Nodes',
        dataKey: 'totalNodes',
      },
      {
        width: 1500,
        flexGrow: 1,
        label: 'Total Sessions',
        dataKey: 'totalSessions',
      },
    ]
  };

  _getCriteriaGridColumns() {
    return [
      {
        width: 2000,
        flexGrow: 1,
        label: 'Criteria Name',
        dataKey: 'name',
      },
      {
        width: 1000,
        flexGrow: 1,
        label: 'Total Nodes',
        dataKey: 'totalNodes'
      },
      {
        width: 1000,
        flexGrow: 1,
        label: 'Percent',
        dataKey: 'percent'
      }
    ];
  };

  _getChartData = (criterias, locationNodeData, selectedCriteriaId) => {
    var pieChartData = [],
      barChartData = [],
      criteriaDataGrid = [],
      sum = 0,
      criteriaData = locationNodeData.criterias[selectedCriteriaId],
      criteria = criterias.filter(x => x.criteriaId === selectedCriteriaId)[0];
    // go through the criteria values for the selected criteria values
    let i = 0;
    for (let cv of criteria.criteriaValues) {
      let cvData = criteriaData[cv.criteriaValueId];
      sum += cvData.count;
      criteriaDataGrid.push({name: cv.value, totalNodes: cvData.count, percent: `${chartUtils.roundToDecimal(cvData.percent * 100, 2)}%`});
      barChartData.push({x: cv.value, y: cvData ? cvData.count : 0});
      pieChartData.push({label: cv.value, angle: cvData ? cvData.percent * 100 : 0, color: chartUtils.getColorByPosition(i)});
      i++;
    }

    return {pieChartData, barChartData, criteriaDataGrid};
  };

  // for some reason we need to change the order of the legend data to match the data in the pie chart
  _getLegendData = (pieChartData) => {
    if (!pieChartData || pieChartData.length === 0) {
      return [];
    }
    let legendData = [];
    for (let i = 0; i < pieChartData.length; i++) {
      let datum = pieChartData[i];
      legendData.push({title: datum.label, color: datum.color});
    }
    return legendData;
  };
}

MetricsPage.contextType = AccountContext;
export default withStyles(styles)(MetricsPage);