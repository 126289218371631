import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import SignUp from './SignUp';

const styles = theme =>  ({
  // insert styles
  landingContent: {
    marginTop: '12px',
    height: '100%',
    width: '84%',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center'
  }
});

const LandingContent = (props) => {
  const { classes, login } = props;
  let content = <SignUp login={login}></SignUp>
  return (
    <div className={classes.landingContent}>
      <Typography align="inherit" gutterBottom variant="h6">
        WELCOME TO BMAPIT!
      </Typography>
      <Typography align="inherit" gutterBottom variant="body1">
        BMAPIT™ is an online platform and tool designed to help researchers collect, upload, store, 
        manage, and visually depict behavior mapping data.  For example, this tool allows you to 
        create several projects, each typically relating to a research paper, and within those projects 
        create separate locations that you intend to research. By collecting and utilizing behavior 
        mapping data in separate sessions, which are scoped to locations, BMAPIT™ can provide you and 
        your business with many different views of the behavior mapping data.
      </Typography>
      <Typography style={{marginTop: '16px'}} align="inherit" variant="body1">
        For inquiries please email admin@bmapit.com
      </Typography>
      <Grid container justify='center'>
        <Grid item md={6} xs={11}>
          {content} 
        </Grid>
      </Grid>
    </div>
  );
}

export default withStyles(styles)(LandingContent);