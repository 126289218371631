import LandingPage from "../components/landing/LandingPage";
import React from 'react';
import { Redirect, BrowserRouter, Switch, Route } from 'react-router-dom';
import constants from '../utils/constants';
import MainLayout from '../components/main-layout/MainLayout';
import SessionPage from '../components/session/SessionPage'
import CriteriaPage from "../components/criteria/CriteriaPage";
import { AccountContext } from '../Context';
import cookies from '../utils/cookieUtils';
import axios from 'axios';
import LocationPage from "../components/location/LocationPage";
import LocationDataPage from '../components/location-data/LocationDataPage';
import MetricsPage from "../components/metrics/MetricsPage";
import Alert from "../components/common/Alert";
import TutorialPage from "../components/tutorial/TutorialPage";

const routes = [{
    path: constants.RTE_SESSION,
    exact: true,
    main: props => (<SessionPage {...props}></SessionPage>),
    alert: true,
  }, {
    path: constants.RTE_CRITERIA,
    exact: true,
    main: props => (<CriteriaPage {...props}></CriteriaPage>),
    alert: true,
  }, {
    path: constants.RTE_LOCATIONS,
    exact: true,
    main: props => (<LocationPage project={props.project}></LocationPage>)
  }, {
    path: constants.RTE_LOCATION_DATA,
    exact: true,
    main: props => (<LocationDataPage></LocationDataPage>)
  }, {
    path: constants.RTE_METRICS,
    exact: true,
    main: props => (<MetricsPage></MetricsPage>)
  }, {
    path: constants.RTE_TUTORIALS,
    exact: true,
    main: props => (<TutorialPage></TutorialPage>)
  }]; 

class AppRoutes extends React.Component {
  _path = undefined;
  state = {
    redirectUrl: undefined,
    alertOptions: {
      open: false,
    }
  };

  componentWillMount() {
    this._path = window.location.pathname;
  };

  componentDidMount() {
    this.checkCookieAuth();
  };

  checkCookieAuth = () => {
    const { setAccountContext } = this.context;
    let authCookie = cookies.readCookie(constants.AUTH_COOKIE);
    if (authCookie && authCookie !== "") {
      // the redirect should the the dashboard if the path is login, or whatever path is
      if (this._path === '/login' || this._path === '/') {
        this._path = constants.RTE_LOCATIONS
      } 

      // attempt to get the account
      axios({
        method: constants.GET_METHOD,
        url: constants.API_SESSION_CONTEXT,
      }).then(response => {
        if (response.status === 200) {
          setAccountContext(response.data);
          this.setState({redirectUrl: this._path});
        }
      })
    }
  };

  openAlert = (options) => {
    if (options) {
      // add default
      options.open = true;
      options.onCancel = this.wrapWithClose(options.onCancel);

      // only add onOK if there is already a function bc it used to show the ok button
      options.onOk = options.onOk && this.wrapWithClose(options.onOk);
      this.setState({
        alertOptions: options,
      })
    }
  };

  wrapWithClose = (func) => () =>{
    this.setState({
      alertOptions: {
        open: false,
      }
    }, func);
  };

  render() {
    const { account } = this.context;
    const { alertOptions } = this.state;
    const { project } = this.props;
    let authenticated = !!(account && account.accountId);
    // make sure we aren't redirecting to ourselves
    return (
      <div className="app-root-container" styles={{width: '100%'}}>
        <BrowserRouter>
            <Switch>                
                {authenticated ? 
                  routes.map(route => (
                    <Route 
                      key={route.path} 
                      exact={route.exact} 
                      path={route.path} 
                      render={(props) => {
                        return (
                          <React.Fragment>
                            {route.alert ? <Alert {...alertOptions}></Alert> : null}
                            <MainLayout {...props}>
                              {route.main({project, openAlert: this.openAlert})}
                            </MainLayout>
                          </React.Fragment>
                        )
                      }}/>
                  ))
                :
                  null
                }

              {/* default routes that are always used should be added here */}	
              {this.renderRedirect()}
              <Route exact path={constants.RTE_LOGIN} component={LandingPage} />
              <Redirect from="/*" to={constants.RTE_LOGIN} />	
            </Switch>
        </BrowserRouter>
      </div>
    );
  }

  renderRedirect = () => {
    const { redirectUrl } = this.state;
    if (window.location.pathname === redirectUrl || !redirectUrl) {
      return null;
    }
    return (<Redirect from="/*" to={redirectUrl} />);
  }
  
};

AppRoutes.contextType = AccountContext;

export default AppRoutes;
