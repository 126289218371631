import Axios from "axios";
import constants from '../utils/constants';


const getProjects = () => {
  return Axios({
    method: constants.GET_METHOD,
    url: constants.API_PROJECTS,
  })
};

const getProjectSummary = (projectId) => {
    return Axios({
      method: constants.GET_METHOD,
      url: constants.API_PROJECT_SUMMARY.replace(':projectId', projectId)
    })
}

const getProject = (projectId) => {
  return Axios({
    method: constants.GET_METHOD,
    url: getUrl(projectId)
  });
};

const setLastProject = (projectId) => {
  return Axios({
    method: constants.PUT_METHOD,
    url: constants.API_SET_LAST_PROJECT.replace(':projectId', projectId)
  })
};

const save = (project) => {
  if (!project) {
    return Promise.reject(new Error('invalid projectId'));
  }
  
  let method = constants.POST_METHOD,
    url = constants.API_PROJECTS.replace(':projectId', project.projectId);
  if (project.projectId) {
    method = constants.PUT_METHOD;
    url = constants.API_PROJECT.replace(':projectId', project.projectId);
  }
  return Axios({
    method: method,
    url: url,
    config: {
      headers: constants.CONTENT_TYPE.JSON
    },
    data: project,
  });
};

function getUrl(projectId) {
  return constants.API_PROJECT.replace(':projectId', projectId);
}

export default {
  getProjects,
  getProject,
  save,
  setLastProject,
  getProjectSummary,
};