import React from 'react';
import { GoogleMap, Marker, withGoogleMap, GroundOverlay } from "react-google-maps";
import { withStyles } from "@material-ui/core";
import { HeatmapLayer } from 'react-google-maps/lib/components/visualization/HeatmapLayer';
import SearchBar from './SearchBar';

const styles = theme => {
  return {
    root: {
      width: '100%',
      marginTop: '10px',
    },
    mainMap: {
      width: '100%',
      minHeight: '480px',
    },
    full: {
      width: '100%',
      height: '100%'
    },
    searchBar: {
      marginTop: '-400px',
      marginLeft: '12px',
    }
  }
}

const defaultCenter = {lat:0, lng: 0};
const defaultAspectRatio = 1.1;
const defaultBounds = {
  north: 0.01,
  south: -0.3,
  east: 0.01,
  west: -0.01
}

// CommonMap attemps to abstract all maps interaction. 
//  Props Descritptions
//  
//  Marker: array of objects that denotes markers
//     lat: latitude
//     lng: longitude,
//  floorplanUrl: url
//  mapElement: component to render map, mainly to set height
//  onMapClick: map click handler

class CommonMap extends React.Component {
  _map = undefined;

  setMapRef = (ref) => {
    this._map = ref;
    if (this.props.setMapRef) {
      this.props.setMapRef(ref);
    }
  }

  onClick = (e) => {
    const { onClick } = this.props;
    if (onClick) {
      onClick({lat: e.latLng.lat(), lng: e.latLng.lng()})
    }
  };

  renderMarkers = () => {
    const { markers, onMarkerClick } = this.props;
    if (!markers || markers.length === 0) {
      return null;
    }

    return markers.map((marker) => {
      const aKey = "lat:" + marker.latitude + "lng:" + marker.longitude;
      return (
        <Marker 
          key={aKey} 
          position={{lat: marker.latitude, lng: marker.longitude}}
          icon={{
            url: '/images/map-icon-xs.png',
            // This marker is 20 pixels wide by 20 pixels high.
            size: new window.google.maps.Size(20, 20),
            // The origin for this image is (0, 0).
            origin: new window.google.maps.Point(0, 0),
            // The anchor for this image is the center of the image.
            anchor: new window.google.maps.Point(10, 10)
          }}
          onClick={(e) => onMarkerClick(e, marker)}
          >
        </Marker>
      );
    });
  }

  renderHeatMap = () => {
    const { markers } = this.props;
    if (markers && markers.length === 0) {
      return null;
    }
    const positions = markers.map(marker => {
      return new window.google.maps.LatLng(marker.latitude, marker.longitude);
    });
    const heatMapData = {    
      positions: positions || [],
      options: {   
        radius: 30,   
        opacity: 0.6,
      }
    };
    return <HeatmapLayer data={heatMapData.positions} options={heatMapData.options}></HeatmapLayer>
  }

  render() {
    let { classes, floorplanUrl, mapElement, bounds, showHeatMaps, showSearchBar, onPlaceLoaded } = this.props;
    if (!bounds) {
      bounds = defaultBounds;
      let diff = bounds.east - bounds.west;
      let height = diff * defaultAspectRatio;
      bounds.north = height/2;
      bounds.south = -height/2;
    }
    return (
      <BaseMap {...this.props}
        className={classes.full}
        onClick={this.onClick}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div className={classes.root} />}
        mapElement={mapElement || <div className={classes.mainMap}></div>}
        setMapRef={this.setMapRef}
      >
        {showSearchBar ? <SearchBar className={classes.searchBar} onPlaceLoaded={onPlaceLoaded}></SearchBar> : null}
        { floorplanUrl ?
          <GroundOverlay onClick={this.onClick} defaultBounds={bounds} defaultUrl={floorplanUrl} opacity={.8}></GroundOverlay>
        :
          null
        }
        { showHeatMaps ? this.renderHeatMap() : this.renderMarkers()}
      </BaseMap>
    )
  };
}

// react-google-maps required a wrapper element
const BaseMap = withGoogleMap((props) => {
  return (
    <GoogleMap
      defaultZoom={15}
      options={{fullscreenControl: false, streetViewControl: false, mapTypeControl: false, minZoom: props.zoomLimit}}
      defaultCenter={defaultCenter}
      ref={props.setMapRef}
      {...props}>
      {props.children}
    </GoogleMap>
  )}
);

export default withStyles(styles)(CommonMap);