import React from 'react';

export const DefaultContext = {
  authenticated: false,
  account: undefined,
  project: undefined,
  setAuthenticated: (auth) => {},
  setAccountContext: (account) => {},
  setProject: (project) => {},
  openAlert: (options) => {},
  closeAlert: (options) => {},
  blockUi: (options) => {},
  unblockUi: (options) => {},
};

export const AccountContext =  React.createContext()