import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 240,
    maxWidth: 300
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: theme.spacing.unit / 4
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

class MultipleSelect extends React.Component {
  state = {
    name: []
  };

  getStyles = (item) => {
    const { theme, value } = this.props;
    return {
      fontWeight:
        value.indexOf(item) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium
    };
  };

  onDelete = (item) => (e) => {
    const { onDelete } = this.props;
    if (onDelete) {
      onDelete(item, e);
    }
  }

  render() {
    const { classes, data, idProp, label, title, value, handleChange, onDelete } = this.props;
    const htmlFor = `select-multiple-chip${title}`;
    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor={htmlFor}>{title}</InputLabel>
        <Select
          multiple
          value={value}
          onChange={handleChange}
          input={<Input id={htmlFor} />}
          MenuProps={MenuProps}
          renderValue={selected => (
            <div className={classes.chips}>
              {selected.map(selectedItem => (
                <Chip key={selectedItem[idProp]} onDelete={this.onDelete(selectedItem)} label={selectedItem[label]} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {data.map(datum => (
            <MenuItem key={datum[idProp]} value={datum}>
              {datum[label]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      
    );
  }
}

MultipleSelect.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(MultipleSelect);
