const mapKeyValue = (obj, ignore, func) => {
  let arr = [];
  for (const key in obj) {
    if (ignore && ignore[key]) {
      continue
    }
    let value = obj[key];
    //optional check for properties from prototype chain
    if (obj.hasOwnProperty(key)) {
      //no a property from prototype chain     
      arr.push(func(key, value));
    }
  }
  return arr;
};

const arrToMap = (keyName, arr) => {
  return arr.reduce((acc, cur) => {
    acc[cur[keyName]] = cur;
    return acc;
  }, {});
}

const findItemAndIndex = (arr, predicate) => {
  let index, item;
  for (let i = 0; i < arr.length; i++) {
    let element = arr[i];
    if (predicate(element)) {
      item = Object.assign({}, element);
      index = i;
      break;
    }
  }
  return {index, item};
}

export default {
  mapKeyValue,
  arrToMap,
  findItemAndIndex
}