import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, withStyles } from '@material-ui/core';

const style = (theme) => ({
  dimScreen: {
    position: 'fixed',
    padding: 0,
    margin: 0,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(232, 236, 241, 0.8)',
    textAlign: 'center',
    zIndex: 1999,
  }
});

class Alert extends React.Component {
  render() {
    const { open, contentText, onCancel, okText, onOk, title } = this.props;
    if (!open) {
      return null;
    }
    return (
        <Dialog
          open={open}
          disableBackdropClick
          disableEscapeKeyDown
          onClose={onCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          {title ? <DialogTitle id="alert-dialog-title">{title}</DialogTitle> : null}
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {contentText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel} autoFocus>Cancel</Button>
            {onOk ?  <Button onClick={onOk} color='primary'>{okText || 'Ok'}</Button> : null}
          </DialogActions>
        </Dialog>
    )
  }
};

export default withStyles(style)(Alert);