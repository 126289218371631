
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

const styles = {
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  logo: {
    width: '119px',
    height: '40px'
  },
  pushRight: {
    flex: 1,
    justifyContent: 'flex-start'
  }
};

class LandingTopBar extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <div className={classes.pushRight}>
              <img alt="main-logo" src="images/logo.png" className={classes.logo}/>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }

}

LandingTopBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LandingTopBar);