import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
  },
  button : {
    marginLeft: '12px'
  }
});


export default withStyles(styles)((props) => {
  let { header, classes } = props;
  return (
    <Grid className={classes.root} container direction="row" justify="center" alignItems="center">
      <Grid item xs={11} lg={9}>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
          <Grid item xs={12} style={{paddingTop: 10, paddingBottom: 10}}>
            <Typography style={{display: 'inline'}} variant="h4">{header || ""}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>{props.children}</Grid>
      </Grid>
    </Grid>
  )
});