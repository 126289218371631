import Axios from "axios";
import constants from '../utils/constants';

const getCriterias = (projectId) => {
  return Axios({
    method: constants.GET_METHOD,
    url: constants.API_CRITERIAS.replace(':projectId', projectId),
  })
};

const getCriteriaNodeCounts = (projectId, locationIds) => {
  let params = undefined;
  if (locationIds && locationIds.length > 1) {
    // convert list into comma seperated list
    params = {
      locationIds: locationIds.join(','),
    }
  } else if (locationIds && locationIds.length === 1) {
    params = {locationIds: locationIds[0]}
  } else if (locationIds) {
    params = {locationIds}
  }
  return Axios({
    method: constants.GET_METHOD,
    url: constants.API_CRITERIAS_NODE_COUNTS.replace(':projectId', projectId),
    params: params,
  });
}

const deleteCriteria = (projectId, criteriaId) => {
  return Axios({
    method: constants.DELETE_METHOD,
    url: constants.API_CRITERIA.replace(':projectId', projectId).replace(':criteriaId', criteriaId)
  });
};

const deleteCriteriaValues = (projectId, criteriaId, cvIds) => {
  return Axios({
    method: constants.PUT_METHOD,
    url: constants.API_CRITERIA_REMOVE_VALUE.replace(':projectId', projectId).replace(':criteriaId', criteriaId),
    config: {
      headers: constants.CONTENT_TYPE.JSON,
    },
    data: cvIds,
  });
};

export default {
  getCriterias,
  deleteCriteria,
  deleteCriteriaValues,
  getCriteriaNodeCounts
};