import React from 'react';
import { Grid, Typography, Paper, Button, Switch, FormControlLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Map from '../common/Map';
import { AccountContext } from '../../Context';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import constants from '../../utils/constants';
import UIBlocker from '../common/UIBlocker';
import locationService from '../../services/locationService';
import nodeService from '../../services/nodeService';
import criteriaService from '../../services/criteriaService';
import sessionService from '../../services/sessionService';
import LocationDataSessionDialog from './LocationDataSessionDialog';
import NodeCreationViewDialog from '../session/NodeCreationViewDialog'
import arrayUtils from '../../utils/arrayUtils';
import PageBase from '../common/PageBase';

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
  },
  button : {
    marginLeft: '12px'
  }
});

class LocationDataPage extends React.Component {
  state = {
    nodes: [],
    location: undefined,
    showHeatMaps: false,
    block: false,
    sessions: [],
    filterSessions: undefined,
    open: false,
    viewNode: undefined,
    criterias: [],
  };

  componentDidMount() {
    let { project } = this.context;
    let { match } = this.props;
    
    var location = undefined;
    // parse the url to get the location
    if (match && match.params && match.params.id) {
      this.setState({block: true}, () => {
        // fetch the location
        const locationId = match.params.id;
        locationService.getLocation(project.projectId, locationId)
        .then(response => {
          if (response.status === 200) {
            location = response.data;
            // fetch nodes and sessions
            Axios.all([
              nodeService.getLocationNodes(project.projectId, location.locationId),
              sessionService.getSessions(project.projectId, location.locationId, true),
              criteriaService.getCriterias(project.projectId)
            ])
            .then(Axios.spread((nodesRes, sessionRes, criteriasRes) => {
              if (nodesRes.status === 200 && sessionRes.status === 200 && criteriasRes.status === 200) {
                this.setState({location: location, nodes: nodesRes.data, sessions: sessionRes.data, criterias: criteriasRes.data})
              }
            }))
            .catch((e) => {throw e});
          }
        }).catch(constants.BASE_ERROR_HANDLER)
        .then(this.unblockCb);
      });
    }
  };

  render() {
    let { classes } = this.props;
    let { location, nodes, showHeatMaps, block, sessions, openFilters, filterSessions, openNode, viewNode, criterias } = this.state;
    const title = `Location Data: ${(location && location.name) || ''}`;
    return (
      <React.Fragment>
        <UIBlocker block={block}></UIBlocker>
        <LocationDataSessionDialog 
          open={openFilters} 
          onApply={this.onApplyFilters} 
          sessions={sessions}
          defaultSelected={filterSessions} 
          onClose={() => this.setState({openFilters: false})}
          onDelete={this.deleteSessions}
        />
        <NodeCreationViewDialog 
          open={openNode} 
          handleClose={() => this.setState({openNode: false})} 
          handleDelete={this.deleteMarker}
          criterias={criterias}
          viewNode={viewNode}
        ></NodeCreationViewDialog>
        <PageBase>
          <Grid container direction="row" justify="flex-start" alignItems="flex-start">
            <Grid item xs={12} style={{paddingTop: 10, paddingBottom: 10}}>
              <Typography style={{display: 'inline'}} variant="h4">{title}</Typography>
              <div  style={{float: 'right', clear: 'left'}}>
                <FormControlLabel 
                  control={<Switch checked={showHeatMaps} color="secondary" onChange={this.toogleHeatMaps}/>} 
                  label="Show Heat Maps" />
                <Button onClick={this.openFilterDialog} className={classes.button} variant='contained'>View Sessions</Button>
                <Button onClick={this.export} className={classes.button} variant="contained">Export</Button>
              </div>
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.root} direction="row" justify="center" alignItems="stretch">
                <Grid item xs={12}>
                  <Paper style={{padding: 12}}>
                    <Map 
                      showHeatMaps={showHeatMaps} 
                      markers={nodes} 
                      mapElement={<div style={{height: '80%', minHeight: '540px'}} />} 
                      zoomLimit={15} 
                      floorplanUrl={location && location.overlayUrl}
                      onMarkerClick={this.onNodeClick}
                    ></Map>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </PageBase>
      </React.Fragment>
    )
  };

  toogleHeatMaps = () => {
    this.setState({showHeatMaps: !this.state.showHeatMaps})
  };

  deleteMarker = () => {
    const { nodes, viewNode } = this.state;
    const { project } = this.context;
    var newNodes = nodes.filter(node => node.nodeId !== viewNode.nodeId);

    if (project) {
      this.setState({block: true}, () => {
        nodeService.deleteNode(project.projectId, viewNode.nodeId)
        .then(({status}) => {
          if (status === 204) {
            // TODO REFACTOR - this is a lot of work to just delete one
            // decrement the number of sessions for the correct session
            const { sessions } = this.state;
            let { index, item } = arrayUtils.findItemAndIndex(sessions, (s) => s.sessionId === viewNode.sessionId)
            item.nodeCount = item.nodeCount - 1;
            let newSesions ;
            if (item.nodeCount === 0) {
              // remove the session from the list
              newSesions = sessions.filter(x => x.sessionId !== viewNode.sessionId);
            } else {
              newSesions = Object.assign([], sessions, {[index]: item});
            }
            this.setState({
              nodes: newNodes, 
              openNode: false, 
              viewNode: undefined,
              block: false,
              sessions: newSesions,
            });
          }
        }).catch(constants.BASE_ERROR_HANDLER);
      });
    }
  };

  onNodeClick = (e, node) => {
    if (node) {
      this.setState({viewNode: node, openNode: true});
    }
  }

  unblockCb = () => {
    this.setState({block: false});
  }

  export = () => {
    let { project } = this.context,
      { location } = this.state;
    window.open(constants.API_NODES_REPORT_BY_LOCATION.replace(':projectId', project.projectId).replace(':locationId', location.locationId))
  };

  openFilterDialog = () => {
    this.setState({openFilters: true})
  }

  onApplyFilters = (event, sessions) => {
    const { project } = this.context;
    const { location } = this.state;
    let sessionIds = sessions.map(x => x.sessionId);
    if (!sessions || sessions.length === 0) {
      this.setState({
        nodes: [],
        filterSessions: undefined,
        openFilters: false,
      });
      return;
    }
    // take the sessions and update the nodes 
    this.setState({block: true}, () => {
      nodeService.getLocationNodes(project.projectId, location.locationId, sessionIds)
      .then(({status, data}) => {
        if (status === 200) {
          this.setState({
            block: false,
            nodes: data,
            filterSessions: sessionIds,
            openFilters: false,
          });
        }
      }).catch(constants.BASE_ERROR_HANDLER)
      .then(() => this.setState({block: false}))
    })
  };

  deleteSessions = (e, sessions) => {
    const { project } = this.context;
    const { location } = this.state;
    if (project && sessions) {
      this.setState({block: true}, () => {
        sessionService.deleteSessions(project.projectId, sessions.map(s => s.sessionId))
        .then(({status}) => {
          if (status === 204) {
            Axios.all([
              nodeService.getLocationNodes(project.projectId, location.locationId),
              sessionService.getSessions(project.projectId, location.locationId, true)
            ])
            .then(Axios.spread((nodesRes, sessionRes) => {
              if (nodesRes.status === 200 && sessionRes.status === 200) {
                this.setState({nodes: nodesRes.data, sessions: sessionRes.data});
              }
            }));
          }
        }).catch(constants.BASE_ERROR_HANDLER)
        .then(() => this.setState({block: false, openFilters: false}))
      });
    }
  }
}

const Wrapper = withRouter(LocationDataPage);
Wrapper.WrappedComponent.contextType = AccountContext;

export default withStyles(styles)(Wrapper);